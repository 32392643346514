<template lang="pug">
div(ref="holder")
  div(@click.capture.stop="handleClick")
    slot(name="activator")
  div
    .brand-context-menu-backdrop(v-if="show" @click.stop="show = false")
    transition(name="fade")
      .brand-context-menu(ref="contextMenu" v-if="show" :style="positionStyle")
        input.form-control(
          type="text"
          :value="tag[label]"
          @input="$emit('change', $event.target.value)"
        )
        om-button.mt-3(@click="remove" xs full-width) {{ $t('remove') }}
</template>

<script>
  import { getBrandedClassString } from '@/components/Elements/Button';

  const OFFSET = 20;
  const HEIGHT = 95;

  export default {
    props: {
      tag: {
        type: Object,
        default: () => ({}),
      },
      trackBy: {
        type: String,
        default: '_id',
      },
      label: {
        type: String,
        default: 'name',
      },
      getRemoveWarningText: {
        type: Function,
        default: () => this.$t('confirmationDialog'),
      },
    },

    data() {
      return {
        show: false,
        position: {
          x: null,
          y: null,
        },
        modalEl: null,
      };
    },

    computed: {
      positionStyle() {
        const left = this.position.x;
        let top;

        const isOutsideOfViewport = this.position.y + OFFSET + HEIGHT > window.innerHeight;

        if (isOutsideOfViewport) {
          top = this.position.y - OFFSET - HEIGHT;
        } else {
          top = this.position.y + OFFSET;
        }

        return {
          left: `${left}px`,
          top: `${top}px`,
        };
      },
      insideModal() {
        return this.modalEl !== null;
      },
    },

    watch: {
      // if running inside a modal, remove the translate from it
      show(v) {
        if (this.insideModal) {
          if (v) {
            this.modalEl.style.setProperty('transform', 'none', 'important');
          } else {
            // wait for animation to finish
            setTimeout(() => {
              this.modalEl.style.transform = null;
            }, 300);
          }
        }
      },
    },

    mounted() {
      this.$nextTick(() => {
        const modalEl = this.$refs.holder.closest('.v--modal-box.v--modal');
        if (modalEl) {
          this.modalEl = modalEl;
        }
      });
    },

    methods: {
      handleClick(e) {
        this.position.x = e.pageX;
        this.position.y = e.pageY;
        this.show = true;
      },
      async remove() {
        this.$modal.show('dialog', {
          text: await this.getRemoveWarningText(this.tag),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$emit('remove', this.tag);
                this.$emit('hide');
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
    },
  };
</script>

<style lang="sass">
  .brand-context-menu
    position: fixed
    top: 0
    left: 0
    transform: translateX(-50%)
    background: white
    box-shadow: rgba(15,15,15,0.05) 0px 0px 0px 1px, rgba(15,15,15,0.1) 0px 3px 6px, rgba(15,15,15,0.2) 0px 9px 24px
    border-radius: 4px
    padding: 14px
    z-index: 12
    cursor: auto
    width: 200px

    &-backdrop
      position: fixed
      left: 0
      top: 0
      height: 100%
      width: 100%
      z-index: 11
      cursor: auto
</style>
