<template lang="pug">
.spinner-input(:id="`spinner-${id}`" :class="classes")
  .spinner-up(@click="up()")
    component(:is="iconComponent('angle-up')" size="1.5em")
  input.form-control.text-center.px-1(
    v-if="editable"
    :id="`spinner-input-${id}`"
    :value.sync="value"
    v-bind="inputProps"
    @keypress="onKeyPress"
    @keyup.enter="set($event)"
    @blur="set($event)"
    autocomplete="off"
  )
  input.form-control.text-center.px-1(
    v-else
    :id="`spinner-input-${id}`"
    :value.sync="value"
    v-bind="inputProps"
  )
  .spinner-down(@click="down()")
    component(:is="iconComponent('angle-down')" size="1.5em")
</template>
<script>
  import { UilAngleUp, UilAngleDown } from '@iconscout/vue-unicons';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: { UilAngleUp, UilAngleDown },
    mixins: [designSystemMixin],
    props: {
      model: {
        prop: 'value',
        event: 'setValue',
      },
      id: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
      },
      editable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      state: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('setValue', v);
        },
      },
      inputProps() {
        return this.editable
          ? {
              maxlength: 2,
              type: 'text',
            }
          : {
              readonly: true,
              type: 'text',
            };
      },
      classes() {
        return {
          'spinner-editable': this.editable,
          'design-system': this.designSystem,
        };
      },
    },
    methods: {
      up() {
        const v = this.state;
        this.$emit('up', v);
      },
      down() {
        const v = this.state;
        this.$emit('down', v);
      },
      set($event) {
        const value = $event.target.value;
        this.state = value;
      },
      onKeyPress(e) {
        if (e.key < '0' || e.key > '9') {
          e.preventDefault();
        }
      },
      iconComponent(icon) {
        const nameParts = icon.split('-').map((iNamePart) => {
          return `${iNamePart.charAt(0).toUpperCase()}${iNamePart.substring(1)}`;
        });
        return `Uil${nameParts.join('')}`;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'spinnerInput.sass'
</style>
