<template lang="pug">
.brand-list-options
  list-option(
    v-for="option in options"
    :key="option.value"
    @click.native="$emit('input', option.value)"
    :selected="value === option.value"
  ) {{ option.key }}
</template>

<script>
  import ListOption from './ListOption.vue';

  export default {
    components: { ListOption },

    props: {
      value: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="sass">
  .brand-list-options
    display: flex
    flex-direction: column
</style>
