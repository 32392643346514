<template lang="pug">
.vc-sketch-field.om-hex-alpha-row.mt-2.vc-gradient
  .vc-sketch-gradient-select.mr-3
    .vc-sketch-gradient-wrapper
      .vc-sketch-gradient-select--color1.gradient-select.gradient-select--active(
        @click="setActive($event, 'color')"
        :style="{ 'background-color': gradientColor1 }"
      )
    .vc-sketch-gradient-wrapper
      .vc-sketch-gradient-select--color2.gradient-select(
        ref="color2Preview"
        @click="setActive($event, 'color2')"
        :style="{ 'background-color': gradientColor2 }"
      )
  span.value-indicator(:class="{ focus: focus, invalid: invalid }")
    input.value-style(
      v-model="gradientDirection"
      @focus="focus = true"
      @blur="isValidValue"
      @keyup="modifyValue"
    )
    span.unit(v-if="gradientDirection !== null") {{ unit }}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import { debounce } from 'lodash-es';
  import editableInput from '../common/EditableInputOm.vue';

  export default {
    name: 'GradientTab',
    components: {
      'ed-in': editableInput,
    },
    mixins: [itemMixin, themeColorsMixin],
    props: {
      property: { type: String, required: true },
      directionProperty: { type: String, required: true },
      min: { type: Number, default: 0 },
      max: { type: Number, default: 200 },
      unit: { type: String, default: '°' },
      defaultValue: { type: Number, default: 1 },
      useGivenMax: { type: Boolean, default: false },
      typeOfComponent: { type: String, default: 'global' },
      alpha: { type: Boolean, default: true },
      colorInstance: { type: Object, default: null },
    },
    data() {
      return {
        focus: false,
        invalid: false,
        maxOverride: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'mobilePreview', 'colorPicker']),
      gradientDirection: {
        get() {
          // TODO remove after all components are migrated to use colorInstance
          if (this.colorInstance) {
            return this.colorInstance.getLinearDirection();
          }

          return this.getValueOf(`${this.property}.linearDirection`, this.defaultValue);
        },
        set(value) {
          // TODO remove after all components are migrated to use colorInstance
          if (this.colorInstance) {
            this.$emit('linearDirectionChange', value);
            return;
          }

          this.setValueOf(`${this.property}.linearDirection`, value);
          window.om.bus.$emit('userInputChange', {
            property: `${this.property}.linearDirection`,
            value,
          });
        },
      },
      gradientColor1() {
        if (this.colorInstance) {
          const color1 = this.colorInstance.getColor();
          if (color1 > -1) {
            return this.getColors[color1];
          }
          return color1;
        }

        const v = this.getValueOf(`${this.property}.color`);
        if (v > -1) {
          return this.getColors[v];
        }
        return v;
      },
      gradientColor2() {
        if (this.colorInstance) {
          const color2 = this.colorInstance.getColor2();
          if (color2 > -1) {
            return this.getColors[color2];
          }
          return color2;
        }

        const v = this.getValueOf(`${this.property}.color2`);
        if (v > -1) {
          return this.getColors[v];
        }
        return v;
      },
      localValue() {
        return this.gradientDirection !== null
          ? this.gradientDirection.toString().replace(/[^0-9-]/g, '')
          : null;
      },
      computedMax() {
        if (this.useGivenMax) return this.max;

        return this.maxOverride || this.max;
      },
    },
    mounted() {
      if (this.colorPicker.input === 2) {
        this.$refs.color2Preview.click();
      }
    },
    methods: {
      ...mapMutations(['updateData']),
      modifyValue: debounce(function (event) {
        if (event.code === 'ArrowDown') {
          if (this.min < this.gradientDirection) {
            this.gradientDirection--;
          }
        } else if (event.code === 'ArrowUp') {
          if (this.gradientDirection < this.max) {
            this.gradientDirection++;
          }
        }
      }, 100),
      inputChange(data) {
        this.$emit('inputChange', data);
      },
      isValidValue() {
        const noLocalValue = this.localValue === '' || this.localValue === null;
        if (noLocalValue && this.mobilePreview) {
          // null value only on mobile!
          this.gradientDirection = null;
          return;
        }
        if (this.computedMax < parseInt(this.localValue, 10)) {
          this.gradientDirection = this.computedMax;
        } else if (this.min > parseInt(this.localValue, 10)) {
          this.gradientDirection = this.min;
        } else {
          this.gradientDirection = this.localValue || this.min;
        }
        this.focus = false;
      },
      setActive(e, prop) {
        e.stopPropagation();
        const options = document.querySelectorAll('.gradient-select');

        options.forEach((el) => {
          el.classList.remove('gradient-select--active');
        });
        e.target.classList.add('gradient-select--active');
        const activeProp = this.colorInstance ? prop : `${this.property}.${prop}`;
        this.$emit('setActiveProp', activeProp);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .unit
    font-size: 14px
</style>
