<template lang="pug">
.brand-info-card(:class="{ 'design-system': designSystem, clickable }")
  div
    .brand-info-card-label {{ label }}
    .brand-info-card-value(:class="valueClass") {{ value }}
    .brand-info-card-total-link(v-if="clickable")
      span.value {{ total }}
      span.text.pl-2
        | {{ $t('total').toLowerCase() }}
        chevron-right.pl-2(color="#ED5A29" :width="16" :height="16")
    .brand-info-card-total(v-else)
      span.value {{ total }}
      span.text.pl-2 {{ $t('total').toLowerCase() }}
  .brand-info-card-icon(v-if="icon" :style="{ backgroundColor: withBackground ? '#FFEFE5' : '' }")
    inline-svg.brand-icon(
      :src="require(`@/assets/admin/svg/icons/${icon}.svg`)"
      :class="`brand-icon-${icon}`"
    )
</template>

<script>
  import ChevronRight from '@/components/Svg/Icons/ChevronRight.vue';

  export default {
    components: { ChevronRight },
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: 0,
      },
      total: {
        type: [String, Number],
        default: 0,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: null,
      },
      valueClass: {
        type: String,
        default: '',
      },
      designSystem: {
        type: Boolean,
        default: true,
      },
      withBackground: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="sass">
  .brand-info-card
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05)
    border-radius: 0.3125rem
    color: #505763
    padding-left: 1.25rem
    padding-right: 1rem
    padding: 1rem 1rem 1rem 1.25rem
    display: flex
    &.clickable:hover
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)
    &-label
      font-size: 0.9375rem
      line-height: 1.25rem

    &-value
      font-size: 1.75rem
      line-height: 2rem
      font-weight: bold
      margin: 0.25rem 0

    &-total
      font-size: 0.75rem
      line-height: 1rem
      color: #8F97A4

    &-total-link
      font-size: 0.875rem
      line-height: 1rem
      color: #ED5A29

    &-icon
      width: 4.75rem
      height: 4.75rem
      border-radius: 0.625rem
      margin-left: auto
      display: flex
      align-items: center
      justify-content: center
</style>
