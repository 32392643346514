<template lang="pug">
.user-segment-saved-segments(
  v-if="!dataLoading"
  :class="{ 'justify-content-center': !items.length }"
)
  EmptyContent(:text="$t(`userSegment.modals.choose.tabs.userdefined.empty`)" v-if="!items.length")
  transition(name="fade")
    .row(v-if="!loading")
      .col-3.choose-segment-item.d-flex.justify-content-center.align-items-center(
        v-if="items.length"
        v-for="item in items"
        @click="select(item._id)"
      )
        .choose-segment-item-inner.py-3.my-3.d-flex.justify-content-center.align-items-center.flex-column.cursor-pointer(
          :class="{ active: selected === item._id }"
        )
          img.choose-segment-item-image(
            :src="item.icon || require('@/assets/admin/svg/segments/user_segment.svg')"
          )
          .choose-segment-item-title.mt-1(:title="getName(item)") {{ getShortenedName(item) }}
      .col-3.choose-segment-item.d-flex.justify-content-center.align-items-center.flex-column.cursor-pointer(
        v-if="showCustom"
        @click="select('custom')"
      )
        .choose-segment-item-inner.py-3.my-3.d-flex.justify-content-center.align-items-center.flex-column.cursor-pointer(
          :class="{ active: selected === 'custom' }"
        )
          img.choose-segment-item-image(
            :src="require('@/assets/admin/svg/segments/custom_targeting.svg')"
          )
          .choose-segment-item-title.mt-1(
            :title="$t(`userSegment.modals.choose.tabs.predefined.custom`)"
          ) {{ $t(`userSegment.modals.choose.tabs.predefined.custom`) }}
</template>
<script>
  import EmptyContent from '@/components/Segments/EmptyContent.vue';

  export default {
    components: { EmptyContent },
    props: {
      items: {
        type: Array,
        required: true,
      },
      showCustom: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: String,
        default: null,
      },
      tab: {
        type: String,
        default: 'predefined',
      },
      dataLoading: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({ loading: true }),
    watch: {
      tab() {
        this.loading = true;
      },
      loading(n) {
        if (n) {
          setTimeout(() => {
            this.loading = false;
          }, 150);
        }
      },
    },
    mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 150);
    },
    methods: {
      select(id) {
        this.$emit('select', id);
      },
      getName(item) {
        const locale = this.$i18n.locale;
        return item.name[locale];
      },
      getShortenedName(item) {
        const original = this.getName(item);
        return original.length > 35 ? `${original.substring(0, 32)}...` : original;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .choose-segment-tab-content .row
    margin: 0 -6px
  .choose-segment-item
    padding: 0 6px
    &-title
      font-size: 1rem
      font-weight: 700
      padding: 0 0.5rem
      line-height: 1rem
      width: 100%
      text-align: center
      height: 2em
      width: 100%
</style>
