<template lang="pug">
.period(:class="classes")
  om-select(
    :id="`period-select-${id}`"
    :label="label"
    :labelPosition="labelPosition"
    :options="options"
    :placeholder="placeholder"
    :renderValue="renderPeriodText"
    :multiple="false"
    :customOptionIsSelected="customOptionIsSelected"
    v-model="innerValue"
    :useCustomOption="true"
    ref="periodSelect"
    :size="size"
  )
    template(slot="last-option")
      om-date-time(
        :id="`period-datetime-${id}`"
        :locale="locale"
        :mode="'range'"
        :customLabels="customLabels"
        :value="customPeriod"
        @datetime="getCustomPeriod($event)"
        :minDate="minDate"
        :maxDate="maxDate"
      )
</template>
<script>
  import moment from 'moment';
  import OmSelect from '@/components/Elements/Select';
  import OmDateTime from '@/components/Elements/DateTime';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: { OmSelect, OmDateTime },
    mixins: [designSystemMixin],
    props: {
      value: {
        type: [Object, Array, String],
      },
      id: {
        type: String,
        required: true,
      },
      locale: {
        default: 'en',
        options: ['en', 'hu'],
        validator: (value) => {
          return ['en', 'hu'].includes(value.toLowerCase());
        },
      },
      options: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: '',
      },
      labelPosition: {
        type: String,
        default: 'top',
        options: ['top', 'half', 'fill'],
        validator: (value) => {
          return ['top', 'half', 'fill'].includes(value.toLowerCase());
        },
      },
      placeholder: {
        type: String,
        default: '',
      },
      minDate: {
        type: [String, Date],
        default: null,
      },
      maxDate: {
        type: [String, Date],
        default: null,
      },
      size: {
        type: String,
        default: 'medium',
        options: ['small', 'medium', 'large'],
        validator: (value) => {
          return ['small', 'medium', 'large'].includes(value.toLowerCase());
        },
      },
    },
    data() {
      return {
        customOptionIsSelected: false,
        customPeriod: null,
        specialLabels: [
          {
            key: 'allTime',
            hu: 'Összes',
            en: 'All time',
          },
        ],
        customLabels: {
          hu: 'Egyedi időszak',
          en: 'Custom period',
        },
        concat: {
          en: 'to',
          hu: '-',
        },
        format: {
          en: 'MM/DD/YYYY',
          hu: 'YYYY/MM/DD',
        },
      };
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
          this.customOptionIsSelected = false;
          return v;
        },
      },
      concatSign() {
        const locale = this.locale;
        return this.concat[locale];
      },
      formatPattern() {
        const locale = this.locale;
        return this.format[locale];
      },
      classes() {
        return {
          'design-system': this.designSystem,
        };
      },
    },
    watch: {
      innerValue: {
        handler(newValue, oldValue) {
          if (newValue.key === 'custom') {
            this.customOptionIsSelected = newValue.key === 'custom';
            const from = new Date(newValue.interval.from);
            const to = new Date(newValue.interval.to);
            this.customPeriod = [from, to];
          } else {
            this.customPeriod = null;
          }
        },
      },
    },
    methods: {
      createSpecialInternalValue(option) {
        return option;
      },
      getCustomPeriod($event) {
        if ($event.key === 'allTime') {
          this.innerValue = this.createSpecialInternalValue($event);
        } else {
          this.innerValue = this.createCustomInternalValue($event);
        }

        if (this.$refs.periodSelect.popoverInstance) {
          this.$refs.periodSelect.popoverInstance.doClose();
        }
        this.customOptionIsSelected = true;
      },
      createCustomInternalValue(dates) {
        const from = moment(dates[0]).format(this.formatPattern);
        const to = moment(dates[1]).format(this.formatPattern);
        return {
          key: 'custom',
          value: this.customLabels[this.locale],
          interval: {
            from,
            to,
          },
        };
      },
      renderPeriodText(option) {
        if (!option) return '';
        if (option.interval.from === 'allTime' || option.interval.to === 'allTime') {
          const label = this.specialLabels.find((label) => label.key === 'allTime');
          return label[this.locale];
        }

        const from = option.interval.from;
        const to = option.interval.to;
        const formatPattern = this.formatPattern;
        const fromText = moment(from).format(formatPattern);
        const toText = moment(to).format(formatPattern);
        return `${fromText} ${this.concatSign} ${toText}`;
      },
    },
  };
</script>
<style lang="sass">
  .flatpickr-calendar
    margin-top: 0.5rem
    margin-left: -0.75rem
    &.open
      z-index: 999999!important
  .period .datetime-toggle
    position: relative
    input
      background: transparent
      color: transparent
      border: 1px solid transparent
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      outline: none
      &:active,
      &:focus
        border: 1px solid transparent
        outline: none
</style>
