<template lang="pug">
.brand-color-picker(:class="classes")
  .brand-color-picker-backdrop(v-if="open" @click="open = false")
  .brand-color-picker-holder
    .brand-color-picker-input(@click="open = true")
      input(:value="value" @input="inputChanged")
      .brand-color-picker-input-color(:style="{ 'background-color': value }")
    transition(name="fade")
      sketch(
        v-if="open"
        :inEditor="false"
        :editMobile="true"
        :value="{ hex: value }"
        @change="onSketchColorChange"
      )
</template>

<script>
  import Sketch from '@/editor/components/color/components/Sketch.vue';

  export default {
    components: {
      Sketch,
    },

    props: {
      value: {
        type: String,
        default: '#fff',
      },
      fluid: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        open: false,
      };
    },

    computed: {
      classes() {
        return {
          'brand-color-picker-fluid': this.fluid,
        };
      },
    },

    methods: {
      inputChanged(event) {
        this.open = false;
        this.$emit('input', event.target.value);
      },
      onSketchColorChange($event) {
        const value = $event.hex.startsWith('#') ? $event.hex : `#${$event.hex}`;
        this.$emit('input', value);
      },
    },
  };
</script>

<style lang="sass">
  .brand-color-picker
    &-holder
      position: relative

    &-backdrop
      position: fixed
      left: 0
      top: 0
      height: 100%
      width: 100%
      z-index: 10

    &-input
      position: relative
      z-index: 11
      display: flex
      width: 9.625rem

      input
        display: block
        width: 100%
        height: 2.5rem
        border: 0.0625rem solid #D8DDE1
        border-radius: 0.1875rem 0rem 0rem 0.1875rem
        padding-left: 8px
        color: #272727
        font-size: 1rem
        line-height: 1.5rem

      &-color
        flex: 0 0 3.125rem
        height: 2.5rem
        border-radius: 0rem 0.1875rem 0.1875rem 0rem

    &-fluid
      .brand-color-picker-input
        width: auto

    .vc-sketch
      position: absolute
      z-index: 11
      top: 3.75rem
</style>
