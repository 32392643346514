<template lang="pug">
.heading(:class="classes" v-bind="$attrs")
  slot
</template>

<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmHeading',
    mixins: [designSystemMixin],
    props: {
      h1: {
        type: Boolean,
        default: false,
      },
      h2: {
        type: Boolean,
        default: false,
      },
      h3: {
        type: Boolean,
        default: false,
      },
      h4: {
        type: Boolean,
        default: false,
      },
      h5: {
        type: Boolean,
        default: false,
      },
      h6: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return {
          'heading-1': this.h1,
          'heading-2': this.h2,
          'heading-3': this.h3,
          'heading-4': this.h4,
          'heading-5': this.h5,
          'heading-6': this.h6,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'heading.sass'
</style>
