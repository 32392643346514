<template lang="pug">
.select-widget-cards.row
  select-widget(
    v-for="(option, index) in options"
    :key="option.value"
    :icon="option.icon"
    :index="index"
    :title="option.key"
    :checked="value && value.includes(option.value)"
    @click="onClick(option)"
  )
</template>

<script>
  import SelectWidget from './SelectWidget.vue';

  export default {
    components: { SelectWidget },

    props: {
      value: {
        type: [String, Array],
        default: null,
      },
      options: {
        type: Array,
        default: () => [],
      },
    },

    methods: {
      onClick(option) {
        this.$emit('input', option.value);
      },
    },
  };
</script>

<style lang="sass">
  .select-widget-cards
    margin-bottom: 12px
    margin-left: -20px
    margin-right: -20px
</style>
