<template lang="pug">
.d-flex.flex-column.code-input-container
  slot(name="label" v-if="label || $slots.label")
    label.form-label(v-if="label" :for="id") {{ label }}
  .input-container.flex-grow-1
    textarea(:id="id" ref="input")
</template>

<script>
  export default {
    props: {
      value: String,
      label: String,
      id: String,
      mode: {
        default: 'javascript',
        validator(value) {
          // The value must match one of these strings
          return ['javascript', 'css', 'json'].indexOf(value) !== -1;
        },
      },
    },
    data() {
      return {
        editor: null,
      };
    },

    watch: {
      value(newValue) {
        if (this.editor.getValue() !== newValue) {
          this.editor.setValue(newValue, -1);
        }
      },
      mode(newMode) {
        if (this.editor) {
          const session = this.editor.getSession();
          session.setMode(`ace/mode/${newMode}`);
        }
      },
    },

    updated() {
      this.$nextTick(() => {
        if (this.editor) {
          this.editor.resize();
        }
      });
    },

    mounted() {
      this.editor = this.initEditor(this.$refs.input, this.mode, this.value);

      this.editor.on('change', () => {
        this.$emit('input', this.editor.getValue());
      });
    },

    beforeDestroy() {
      if (this.editor) {
        this.editor.destroy();
      }
    },

    methods: {
      initEditor(input, mode, defaultValue = null) {
        const editor = window.ace.edit(input);
        const session = editor.getSession();
        session.setMode(`ace/mode/${mode}`);
        session.setTabSize(2);
        if (defaultValue) {
          session.setValue(defaultValue, -1);
        }
        editor.$blockScrolling = Infinity;
        return editor;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors.sass'
  @import '../../sass/components/_forms.sass'

  .code-input-container
    width: 100%
    height: 100%

    .input-container
      width: 100%
      height: 100%
</style>

<style lang="sass">
  .code-input-container .input-container pre
    width: 100%
    height: 100%
</style>
