<template lang="pug">
  .vc-image-align-select
    .vc-image-align-select--label {{ $t(label) }}
    .vc-image-align-select--options.row.px-3.mt-2
      .col.option(v-for="({ key, value }) in options" :key="key" @click="setValue(value, key)")
        i.vc-image-align-select--icon.mr-1(:class="[label]" :id="`icon-${getComponentId(key)}`")
          component(:is="getComponentId(key)")
        span.option-label(:class="[label]" :id="`label-${getComponentId(key)}`") {{i18n ? $t(key) : key}}
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import HorizontalRight from '@/editor/components/svg/HorizontalRight';
  import HorizontalLeft from '@/editor/components/svg/HorizontalLeft';
  import HorizontalCenter from '@/editor/components/svg/HorizontalCenter';
  import VerticalTop from '@/editor/components/svg/VerticalTop';
  import VerticalCenter from '@/editor/components/svg/VerticalCenter';
  import VerticalBottom from '@/editor/components/svg/VerticalBottom';

  export default {
    name: 'ImageAlignSelect',
    components: {
      HorizontalRight,
      HorizontalLeft,
      HorizontalCenter,
      VerticalTop,
      VerticalCenter,
      VerticalBottom,
    },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String },
      options: { type: Array, default: () => [] },
      i18n: { type: Boolean, default: true },
      defaultValue: { type: String, default: null },
      mobileDefaultValue: { type: [String, Number, Array, Object, null], default: null },
      typeOfComponent: { type: String, default: 'global' },
    },
    computed: {
      isMobileProperty() {
        return this.property.includes('mobile.');
      },
    },
    mounted() {
      this.setActiveOption();
    },
    methods: {
      getComponentId(key) {
        return `${this.label}-${key}`;
      },
      setValue(value, key) {
        this.setValueOf(this.property, value);
        this.setActiveOption(key);
        window.om.bus.$emit('userInputChange', { property: this.property, value });
      },
      getValue() {
        const _def = this.isMobileProperty ? this.mobileDefaultValue : this.defaultValue;
        return this.getValueOf(this.property, _def);
      },
      setActiveOption(key) {
        const ACTIVE_CLASS = 'selected';
        if (!key) key = this.getValue();

        document
          .querySelectorAll(`.${this.label}.selected`)
          .forEach((el) => el.classList.remove(ACTIVE_CLASS));

        const labelId = `label-${this.getComponentId(key)}`;
        const iconId = `icon-${this.getComponentId(key)}`;

        const label = document.getElementById(labelId);
        const icon = document.getElementById(iconId);

        if (label) label.classList.add(ACTIVE_CLASS);
        if (icon) icon.classList.add(ACTIVE_CLASS);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .option-active
    background-color: #ED5A29
</style>
