<template lang="pug">
.sidebar-input-wrapper
  .sidebar-upload-area(
    @click="$emit('click')"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  )
    #sidebar-upload-remove-icon.sidebar-upload-area-remove(
      v-if="src && editMobile"
      :class="mobilePreview && hover ? 'sidebar-upload-area-remove-show' : ''"
      @click.stop="$emit('remove')"
    )
      trash-svg
    template(v-if="src && src.length")
      .overlay-icon(v-if="inColorPicker")
        img(src="@/assets/editor/svg/editIcon.svg" alt="edit")
        span.ml-2 {{ $t('changeImage') }}
      inline-svg.upload-area-inline-svg(v-if="isSvg" :src="src")
      img(v-else :src="src")
    template(v-else)
      .overlay-icon(v-if="inColorPicker")
        img(src="@/assets/editor/svg/editIcon.svg" alt="add")
        span.ml-2 {{ $t('addImage') }}
    .sidebar-upload-area-mobile-version(v-if="editMobile && !src && mobilePreview && !hover") {{ $t('desktopImageByDefault') }}
    .sidebar-upload-area-mobile-only(v-if="editMobile && !src && mobilePreview && hover") {{ $t('addMobileOnlyImage') }}
</template>

<script>
  import { mapState } from 'vuex';
  import TrashSvg from '@/components/Svg/TrashSvg';
  import { isSvgUrl } from '@/editor/util';

  export default {
    components: { TrashSvg },

    props: {
      src: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      inColorPicker: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        hover: false,
      };
    },

    computed: {
      ...mapState(['mobilePreview']),
      isSvg() {
        return isSvgUrl(this.src);
      },
    },

    watch: {
      hover() {
        this.$emit('hover', this.hover);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .sidebar-upload-area
    background: rgba(196, 196, 196, 1)
    cursor: pointer
</style>
