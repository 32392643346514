<template lang="pug">
.box
  .d-flex.align-items-center.justify-content-between
    .box-title(:style="{ fontSize: `${fontSize}px` }") {{ title }}
    .button.om-close(style="color: #b9bec6" @click="close()" width="12" height="12") &times;
  .box-text(v-if="text") {{ text + ' ' }}
    a.box-link(v-if="learnMoreUrl" :href="learnMoreUrl" target="_blank") {{ $t('learnMore') }}
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      learnMoreUrl: {
        type: String,
        default: null,
      },
      id: {
        type: String,
        default: null,
      },
      fontSize: {
        type: Number,
        default: 16,
      },
    },
    methods: {
      async close() {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
        await this.$store.dispatch('setInfoBoxVisibility', { box: `hide${this.id}`, hide: true });
      },
    },
  };
</script>

<style lang="sass">
  .box
    background: #F7F7F8
    border-radius: 4px
    padding: 16px 12px
    color: #505763
  .box-title
    font-weight: 700
  .box-text
    font-weight: 400
    font-size: 12px
    line-height: 20px
    margin-top: 8px
  .box-link
    color: #505763
    text-decoration: underline
    &:hover
      color: #505763
</style>
