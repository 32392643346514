<template lang="pug">
.select(:class="classes" ref="selectRoot")
  label.select-label(:for="id" v-if="label")
    .d-flex.align-items-center
      span(v-html="label")
      slot(name="label-tooltip")
  .select-wrapper(ref="selectWrapper")
    .select-shadow-placeholder(v-if="dropdownOpen && multiple")
    popper(
      :ref="`popper_${id}`"
      trigger="click"
      :disabled="disabled"
      :options="popperOptions"
      @created="popoverInstance = $event"
      @show="watchPopper"
      @hide="watchPopper"
    )
      .popper.brand-popper.select-popper(:style="{ 'min-width': dropdownWith }")
        .select-options(:class="{ 'select-options-scroller': scrollerVisible }")
          .select-option(
            v-for="(option, index) in renderedOptions"
            :class="{ 'select-option-selected': showIsSelected(option), 'select-option-disabled': optionIsDisabled(option) }"
            @click="onOptionClick(option)"
            :disabled="optionIsDisabled(option)"
            :ref="`select-option-${id}-${index}`"
            v-tooltip="{content: optionTooltips[option[optionKey]], loadingContent: 'Please wait...', loadingClass: 'content-is-loading'}"
          )
            slot(name="prepend-option" :option="option")
            .select-option-value.text-truncate(:class="`select-option-value-${id}-${index}`")
              span {{ option[optionText] }}
            .select-option-subtext(v-if="option[optionSubtext]") {{ option[optionSubtext] }}
            .select-option-status
              component.select-delete-icon(:is="iconComponent('times')" :size="iconSize")
              component.select-selected-icon(:is="iconComponent('check')" :size="iconSize")
            .select-option-actions
              component.action-modify(
                v-if="option.editable || editable"
                :is="iconComponent('pen')"
                :size="iconSize"
                @click.stop.native="$emit('edit', option)"
              )
              component.action-delete(
                v-if="option.deletable || deletable"
                :is="iconComponent('trash-alt')"
                :size="iconSize"
                @click.stop.native="removeOption(option)"
              )
          .select-option.select-option-last(
            :class="{ 'select-option-selected': customOptionIsSelected }"
            v-if="useCustomOption"
          )
            slot(name="last-option")
            slot(name="last-option-status")
              .select-option-status
                component.select-delete-icon(:is="iconComponent('times')" :size="iconSize")
                component.select-selected-icon(:is="iconComponent('check')" :size="iconSize")
          .select-option.select-option-extendable(v-if="extendable" @click="addNewOption")
            span {{ addNewText }}

      template(slot="reference")
        .select-content.form-control(:class="formClasses")
          span.select-toggle(v-if="!iconOnly")
            component.select-toggle-down(
              :is="iconComponent('angle-up')"
              :size="iconSize"
              v-if="dropdownOpen"
            )
            component.select-toggle-up(:is="iconComponent('angle-down')" :size="iconSize" v-else)
          slot(name="placeholder")
            span.select-placeholder(v-if="isShowPlaceholder" @click="placeholderClick") {{ placeholder }}
          component.select-icon(v-if="iconOnly" :is="iconComponent(iconType)" :size="iconSize")
          slot(name="multiple-selections")
            .select-multi-selections(v-if="isShowMultiSelection")
              .select-first-multi-item(:class="{ truncated: selectedIsTruncated }")
                om-chip(
                  :title="selectedIsTruncated ? firstMultiItem : null"
                  small
                  removable
                  @click.native.stop=""
                  color="secondary"
                  @remove="removeElement(firstMultiItem)"
                ) {{ firstMultiItem }}
              span.select-overflow-items(v-if="overflowItems") {{ overflowItems }}
          slot(name="select-input")
            input.text-left.select-input(
              v-if="!iconOnly"
              ref="selectInput"
              autocomplete="off"
              :type="searchable || multiple ? 'text' : 'button'"
              :id="id"
              :disabled="disabled"
              :value="selectInputValue"
              @keyup.enter="$emit('addNew', selection)"
              @input="filter($event.target.value); $emit('filter', $event.target.value)"
            )
            span.select-selection(v-if="!iconOnly" ref="selectInputSelection") {{ selectInputValue }}
          .clearfix
    .select-messages
      slot(name="helper-message")
        .helper-message(v-if="helperMessage && !error" v-html="helperMessage")
      slot(name="error-message")
        .error-message(v-if="error") {{ errorMessage }}
</template>

<script>
  import {
    UilTimes,
    UilAngleUp,
    UilAngleDown,
    UilCheck,
    UilTrashAlt,
    UilPen,
    UilMobileAndroid,
    UilListUl,
  } from '@iconscout/vue-unicons';
  import Popper from 'vue-popperjs';
  import OmChip from '@/components/Elements/Chips/Chip.vue';
  import OmTooltip from '@/components/Elements/Tooltip/Tooltip.vue';
  import designSystemMixin from '../mixins/designSystem';

  const OPTION_WIDTHS = {
    20: '250px',
    35: '350px',
  };

  export default {
    components: {
      OmChip,
      OmTooltip,
      Popper,
      UilTimes,
      UilAngleUp,
      UilAngleDown,
      UilCheck,
      UilTrashAlt,
      UilPen,
      UilMobileAndroid,
      UilListUl,
    },
    mixins: [designSystemMixin],
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      value: {
        type: [Object, Array, String],
      },
      label: {
        type: String,
        default: '',
      },
      labelPosition: {
        type: String,
        default: 'top',
        options: ['top', 'half', 'fill'],
        validator: (value) => {
          return ['top', 'half', 'fill'].includes(value.toLowerCase());
        },
      },
      hideMultiSelection: {
        type: Boolean,
        default: false,
      },
      helperMessage: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      customOptionIsSelected: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      searchable: {
        type: Boolean,
        default: false,
      },
      deSelectable: {
        type: Boolean,
        default: false,
      },
      deletable: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      extendable: {
        type: Boolean,
        default: false,
      },
      addNewText: {
        type: String,
        default: null,
      },
      options: {
        type: Array,
        default: () => [],
      },
      size: {
        type: String,
        default: 'medium',
        options: ['small', 'medium', 'large'],
        validator: (value) => {
          return ['small', 'medium', 'large'].includes(value.toLowerCase());
        },
      },
      iconSize: {
        type: String,
        default: '1.5em',
      },
      optionKey: {
        type: [String, Number],
        default: 'key',
      },
      optionText: {
        type: [String, Number],
        default: 'value',
      },
      optionSubtext: {
        type: [String, Number],
        default: 'subText',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      renderValue: {
        type: Function,
        default(option) {
          if (!option) return '';
          if (typeof option === 'string') return option; // for searchable
          return option[this.optionText];
        },
      },
      useCustomOption: {
        type: Boolean,
        default: false,
      },
      iconOnly: {
        type: Boolean,
        default: false,
      },
      iconType: {
        type: String,
        default: 'list-ul',
      },
    },

    data() {
      return {
        maxOptionLength: 0,
        selectWidth: 0,
        selectHeight: 0,
        optionLengths: [],
        dropdownWith: 'initial',
        popoverInstance: null,
        selection: '',
        filteredOptions: [],
        scrollerVisible: false,
        dropdownOpen: false,
        selectedHovered: false,
        multipleSelections: [],
        optionTooltips: {},
      };
    },
    computed: {
      selectClassWithId() {
        return `select-${this.id}`;
      },
      selectInputValue() {
        return this.multiple ? this.selection : this.renderValue(this.selection);
      },
      popperOptions() {
        return {
          html: true,
          container: 'body',
          placement: 'bottom-start',
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: this.$refs.selectWrapper,
              fn(data) {
                const newData = data;
                const referenceWidth = newData.offsets.reference.width;
                const popperWidth = newData.offsets.popper.width;
                const widthDifferenceOfReferenceAndPopper = popperWidth - referenceWidth;
                const referenceRect = data.instance.reference.getBoundingClientRect();
                const referenceRightFromScreenSide = window.innerWidth - referenceRect.right;

                if (window.innerWidth <= 768) {
                  return data;
                }

                if (referenceWidth === popperWidth) {
                  return data;
                }

                if (referenceRightFromScreenSide > widthDifferenceOfReferenceAndPopper) {
                  return data;
                }

                // manually set position to bottom-end placement
                newData.offsets.popper.top = newData.offsets.reference.height;
                newData.offsets.popper.left = referenceWidth - popperWidth;
                return newData;
              },
              order: 850,
            },
          },
        };
      },
      internalValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      isShowPlaceholder() {
        return this.hideMultiSelection
          ? this.selectInputValue === ''
          : this.selectInputValue === '' && !this.multipleSelections.length;
      },
      isShowMultiSelection() {
        return this.hideMultiSelection ? !this.hideMultiSelection : this.firstMultiItem;
      },
      overflowItems() {
        const multiItems = this.multipleSelections.length;
        const multiItemsReduceOne = multiItems - 1;
        return multiItemsReduceOne > 0 ? `+${multiItemsReduceOne}` : '';
      },
      selectedIsTruncated() {
        return this.firstMultiItem.length > 22;
      },
      firstMultiItem() {
        const first = this.multipleSelections[0];
        if (first !== undefined) {
          return first;
        }
        return first;
      },
      formClasses() {
        return {
          'form-control-lg': this.size === 'large',
          'form-control-sm': this.size === 'small',
          'select-is-focused': this.dropdownOpen,
          'is-invalid': this.error,
          'p-2': this.iconOnly,
        };
      },
      renderedOptions() {
        return this.filteredOptions.length ? this.filteredOptions : this.options;
      },
      classes() {
        return {
          'select-position-top': this.labelPosition === 'top',
          'select-position-half': this.labelPosition === 'half',
          'select-position-fill': this.labelPosition === 'fill',
          'select-dropdown-open': this.dropdownOpen,
          'select-has-options': this.filteredOptions.length
            ? this.filteredOptions.length
            : this.options.length,
          'select-hide-multi-selections': this.hideMultiSelection,
          'select-has-item-selected': this.multiple && this.multipleSelections.length,
          'select-disabled': this.disabled,
          'select-lg': this.size === 'large',
          'select-sm': this.size === 'small',
          'select-multiple': this.multiple,
          'select-can-deselect': this.deSelectable,
          'select-multiple-none-selected': this.multipleSelections.length === 0 && this.multiple,
          'select-searchable': this.searchable || this.multiple,
          'select-deletable': this.deletable,
          'select-editable': this.editable,
          'select-extendable': this.extendable,
          'design-system': this.designSystem,
          [`${this.selectClassWithId}`]: true,
        };
      },
    },
    watch: {
      internalValue: {
        handler() {
          this.setScroller(this.options);
          this.setSelection();
        },
        deep: true,
      },
      options: {
        handler() {
          this.setScroller(this.options);
          this.setSelection();
          this.initDropdownWidth();
        },
        deep: true,
      },
      renderedOptions(elements) {
        this.setScroller(elements);
      },
      multipleSelections() {
        if (!this.popoverInstance || !this.popoverInstance.popperJS) return;
        this.popoverInstance.popperJS.update();
      },
      dropdownOpen(v) {
        if (v) {
          this.setOptionTooltips();
        }
      },
    },
    mounted() {
      this.setScroller(this.options);
      this.setSelection();
      this.initDropdownWidth();
    },
    methods: {
      optionIsDisabled(option) {
        return option?.disabled ? option.disabled : false;
      },
      addNewOption() {
        if (this.searchable) {
          this.$emit('addNew', this.selection);
        } else {
          this.$emit('addNew');
        }
      },
      removeOption(option) {
        this.$emit('remove', option);
        this.resetSelection();
        this.resetOptions();
      },
      calcSelectDimension() {
        this.selectWidth = this.$refs.selectRoot.clientWidth;
        this.selectHeight = this.$refs.selectRoot.clientHeight;
      },
      setOptionTooltips() {
        this.$nextTick(() => {
          this.renderedOptions.forEach((option, index) => {
            const el = document.querySelector(`.select-option-value-${this.id}-${index}`);
            if (el) {
              if (el.offsetWidth < el.scrollWidth) {
                this.optionTooltips[option[this.optionKey]] = option[this.optionText];
              } else {
                this.optionTooltips[option[this.optionKey]] = '';
              }
            }
          });
          this.$forceUpdate();
        });
      },
      initDropdownWidth() {
        this.getOptionsLength();
        this.calcSelectDimension();
        this.calcDropdownWidth();
      },
      calcDropdownWidth() {
        for (const [key, value] of Object.entries(OPTION_WIDTHS)) {
          if (this.maxOptionLength > parseInt(key, 10)) {
            this.dropdownWith = value;
          }
        }
      },
      getOptionsLength() {
        this.optionLengths = [];
        this.options.forEach((element) => {
          const optionTextLength = element[this.optionText] ? element[this.optionText].length : 0;
          const optionSubtextLength = element[this.optionSubtext]
            ? element[this.optionSubtext].length
            : 0;
          const elementLength = optionTextLength + optionSubtextLength;
          this.optionLengths.push(elementLength);
        });
        this.getMaxOptionLength();
      },
      getMaxOptionLength() {
        this.maxOptionLength = Math.max(...this.optionLengths);
      },
      deSelectOption() {
        const optionKey = this.optionKey;
        const optionText = this.optionText;
        this.selection = { [optionKey]: '', [optionText]: '' };
        this.internalValue = { [optionKey]: '', [optionText]: '' };
      },
      assignOption(option) {
        this.selection = option;
        this.internalValue = option;
      },
      singleSelectOptionClick(option) {
        if (this.deSelectable) {
          const optionKey = this.optionKey;

          if (this.internalValue == null) {
            this.assignOption(option);
            return;
          }

          if (this.internalValue[optionKey] === option[optionKey]) {
            this.deSelectOption();
          } else {
            this.assignOption(option);
          }
        }

        if (!this.deSelectable) {
          this.assignOption(option);
        }
      },
      multipleSelectOptionClick(option) {
        const optionText = this.optionText;
        if (this.multipleSelections.includes(option[optionText])) {
          this.removeElement(option[optionText]);
        } else {
          const internalValue = this.internalValue || [];
          internalValue.push(option);
          this.internalValue = internalValue;
        }
        if (this.hideMultiSelection) {
          this.resetSelection();
        }
      },
      onOptionClick(option) {
        this.popoverInstance.doClose();

        if (!this.multiple) {
          this.singleSelectOptionClick(option);
        }

        if (this.multiple) {
          this.multipleSelectOptionClick(option);
        }

        this.resetOptions();
      },
      removeElement(elementName) {
        const internalValue = this.internalValue;
        const optionText = this.optionText;
        this.internalValue.forEach((element, index) => {
          if (element[optionText] === elementName) {
            internalValue.splice(index, 1);
          }
        });
        this.internalValue = internalValue;
      },
      showIsSelected(option) {
        const key = this.optionKey;
        if (this.multiple) {
          return this.multipleSelections.includes(option[this.optionText]);
        }

        return option[key] === this.internalValue?.[key];
      },
      setInputIfSingleSelect() {
        const optionKey = this.optionKey;
        const selection = this.options.find((option) => {
          return option[optionKey] === this.internalValue[optionKey] ? option : '';
        });
        this.selection = this.useCustomOption ? this.internalValue : selection || '';
      },
      setInputIfMultipleSelect() {
        const optionText = this.optionText;
        const multipleSelections = [];
        this.internalValue.forEach((element) => {
          multipleSelections.push(element[optionText]);
        });
        this.multipleSelections = multipleSelections;
      },
      setSelection() {
        if (this.internalValue) {
          if (!this.multiple) {
            this.setInputIfSingleSelect();
          }

          if (this.multiple) {
            this.setInputIfMultipleSelect();
          }
        }

        if (this.internalValue === null) {
          this.resetSelection();
        }
      },
      setScroller(options) {
        this.scrollerVisible = this.scrollerIsVisible(options);
      },
      placeholderClick() {
        this.$refs.selectInput.focus();
      },
      watchPopper(popper) {
        if (!this.disabled) {
          this.dropdownOpen = popper.showPopper;

          if (this.dropdownOpen && (this.multiple || this.searchable)) {
            this.$nextTick(() => {
              this.resetSelection();
              this.$refs.selectInput.focus();
            });
          }

          if (!this.dropdownOpen) {
            if (this.searchable && !this.multiple) {
              this.$nextTick(() => {
                this.selection = this.internalValue;
                this.resetOptions();
                this.$refs.selectInput.blur();
              });
            }

            if (this.multiple) {
              this.$nextTick(() => {
                this.resetSelection();
                this.resetOptions();
                this.$refs.selectInput.blur();
              });
            }
          }
        }
      },
      iconComponent(icon) {
        const nameParts = icon.split('-').map((iNamePart) => {
          return `${iNamePart.charAt(0).toUpperCase()}${iNamePart.substring(1)}`;
        });
        return `Uil${nameParts.join('')}`;
      },
      resetSelection() {
        this.selection = '';
      },
      resetOptions() {
        this.filteredOptions = [];
      },
      scrollerIsVisible(obj) {
        return obj.length > 7;
      },
      filter(v) {
        const { options, optionText } = this;
        const escapedV = v.replace(/\*/g, '\\$&');
        const regex = new RegExp(escapedV, 'i');

        const filteredArray = options.filter((elem) => regex.test(elem[optionText]));

        this.filteredOptions = filteredArray;
        this.selection = v;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'select.sass'
</style>
<style lang="sass">
  .select-first-multi-item
    .chip-content
      white-space: nowrap

  .select-first-multi-item.truncated
    .chip-content
      max-width: 140px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      display: inline-block

  .popper.select-popper
    .popper__arrow
      display: none!important
</style>
