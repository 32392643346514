<template>
  <div
    ref="container"
    class="vc-saturation"
    :style="{ background: bgColor }"
    @mousedown="handleMouseDown"
    @touchmove="handleChange"
    @touchstart="handleChange"
  >
    <div class="vc-saturation--white"></div>
    <div class="vc-saturation--black"></div>
    <div class="vc-saturation-pointer" :style="{ top: pointerTop, left: pointerLeft }">
      <div class="vc-saturation-circle"></div>
    </div>
  </div>
</template>

<script>
  import { throttle } from 'lodash-es';

  const frame = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace ? workspace.contentWindow : null;
  };

  export default {
    name: 'Saturation',
    props: {
      value: Object,
      hue: Number,
    },
    // OM Change
    data() {
      return {
        left: null,
        top: null,
      };
    },
    computed: {
      colors() {
        return this.value;
      },
      bgColor() {
        return `hsl(${this.colors.hsv.h}, 100%, 50%)`;
      },
      pointerTop() {
        return this.colors ? `${-(this.colors.hsv.v * 100) + 1 + 100}%` : `${this.top}px`;
      },
      pointerLeft() {
        return this.colors ? `${this.colors.hsv.s * 100}%` : `${this.left}px`;
      },
    },
    methods: {
      throttle: throttle(
        (fn, data) => {
          fn(data);
        },
        20,
        {
          leading: true,
          trailing: false,
        },
      ),
      handleChange(e, skip) {
        if (!skip) e.preventDefault();
        const container = this.$refs.container;
        if (!container) return;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;

        const leftOffset = container.getBoundingClientRect().left + window.pageXOffset;
        const topOffset = container.getBoundingClientRect().top + window.pageYOffset;

        const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
        const pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
        let left = pageX - leftOffset;
        let top = pageY - topOffset;

        if (left < 0) {
          left = 0;
        } else if (left > containerWidth) {
          left = containerWidth;
        }
        if (top < 0) {
          top = 0;
        } else if (top > containerHeight) {
          top = containerHeight;
        }
        // OM Change
        this.left = left;
        this.top = top;

        const saturation = left / containerWidth;
        let bright = -(top / containerHeight) + 1;

        bright = bright > 0 ? bright : 0;
        bright = bright > 1 ? 1 : bright;

        this.throttle(this.onChange, {
          h: this.colors.hsv.h,
          s: saturation,
          v: bright,
          a: this.colors.hsv.a,
          source: 'hsva',
        });
      },
      onChange(param) {
        this.$emit('change', param);
      },
      handleMouseDown() {
        // this.handleChange(e, true)
        window.addEventListener('mousemove', this.handleChange);
        window.addEventListener('mouseup', this.handleChange);
        window.addEventListener('mouseup', this.handleMouseUp);

        const frameWindow = frame();
        if (frameWindow) {
          frameWindow.addEventListener('mousemove', this.handleChange);
          frameWindow.addEventListener('mouseup', this.handleChange);
          frameWindow.addEventListener('mouseup', this.handleMouseUp);
        }
      },
      handleMouseUp() {
        this.unbindEventListeners();
      },
      unbindEventListeners() {
        window.removeEventListener('mousemove', this.handleChange);
        window.removeEventListener('mouseup', this.handleChange);
        window.removeEventListener('mouseup', this.handleMouseUp);

        const frameWindow = frame();
        if (frameWindow) {
          frameWindow.removeEventListener('mousemove', this.handleChange);
          frameWindow.removeEventListener('mouseup', this.handleChange);
          frameWindow.removeEventListener('mouseup', this.handleMouseUp);
        }
      },
    },
  };
</script>
