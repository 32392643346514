<template lang="pug">
.pagination-list(:class="classes")
  .row
    .col.col-auto
      om-select(
        labelPosition="fill"
        :label="label"
        size="small"
        :id="selectId"
        :options="renderedLimits"
        :value="{ key: value, value: value }"
        @input="changePaginationLimit($event)"
      )
    .col
      .pagination-results
        div
          span.page-from {{ pageFrom }}
          span.px-1 -
          span.page-to {{ pageTo }}
          span.page-separator /
          span.page-all {{ allPageNum }}
</template>
<script>
  import OmSelect from '@/components/Elements/Select/';
  import { nanoid } from 'nanoid';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: { OmSelect },
    mixins: [designSystemMixin],
    model: {
      prop: 'value',
      event: 'paginationLimitChange',
    },
    props: {
      label: {
        type: String,
        default: 'View',
      },
      value: {
        default: 30,
        type: Number,
      },
      pageFrom: {
        default: 1,
        type: Number,
      },
      pageTo: {
        default: 10,
        type: Number,
      },
      allPageNum: {
        default: 10,
        type: Number,
      },
      limits: {
        type: Array,
        default: () => {
          return [30, 50, 100];
        },
      },
    },
    computed: {
      selectId() {
        const nanoId = nanoid(8);
        return `pagination-select-${nanoId}`;
      },
      paginationLimit: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('paginationLimitChange', v);
        },
      },
      renderedLimits() {
        const limits = [];
        this.limits.forEach((element) => {
          limits.push({ key: element, value: element });
        });
        return limits;
      },
      classes() {
        return {
          'design-system': this.designSystem,
        };
      },
    },
    methods: {
      changePaginationLimit(event) {
        this.paginationLimit = event.value;
      },
    },
  };
</script>
<style lang="sass">
  @import 'paginationList.sass'
</style>
