<template lang="pug">
.vc-sketch-bottom
  .vc-sketch-field.om-hex-alpha-row.mt-2
    .vc-sketch-field--single
      ed-in(label="hex" :value="hex" @change="inputChange")
    .vc-sketch-field--single.d-flex(v-if="!disableAlpha")
      span.vc-input__label {{ $t('opacity') }}
      span.value-indicator
        input.value-style(v-model="colorAlpha" :max="100")
        span.unit %
  .vc-sketch-controls.mt-3
    .vc-sketch-sliders
      .vc-sketch-hue-wrap
        hue(v-model="colors" @change="childChange")
      .vc-sketch-alpha-wrap.mt-3(v-if="!disableAlpha")
        alpha(v-model="colors" @change="childChange")
</template>

<script>
  import hue from '@/editor/components/color/components/common/Hue.vue';
  import alpha from '@/editor/components/color/components/common/Alpha.vue';
  import editableInput from '@/editor/components/color/components/common/EditableInputOm.vue';

  export default {
    name: 'SolidTab',
    components: {
      hue,
      alpha,
      'ed-in': editableInput,
    },
    props: ['disableAlpha', 'colors'],
    data() {
      return {
        colorAlpha: 0,
      };
    },
    computed: {
      hex() {
        return this.colors.hex;
      },
    },
    watch: {
      hex(v) {
        this.$emit('change', { hex: v });
      },
      colors(v) {
        this.colorAlpha = Math.round(v.a * 100);
      },
    },
    mounted() {
      this.colorAlpha = Math.round(this.colors.a * 100);
    },
    methods: {
      inputChange(data) {
        this.$emit('inputChange', data);
      },
      childChange(data) {
        const { a } = data;
        this.colorAlpha = Math.round(a * 100);
        this.$emit('childChange', data);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .vc-input__label
    align-self: center !important
    font-size: 12px !important
    line-height: 16px !important
    padding: 0 !important
    color: #505763 !important
    margin-right: 7px !important
    text-align: left !important
    display: block !important
  .value-indicator
    padding: 0 2rem 0 1rem
    pointer-events: none
    cursor: not-allowed
    .value-style
      align-self: center
    .unit
      align-self: center
      font-size: 14px
</style>
