import { mapState, mapGetters } from 'vuex';
import { get as _get } from 'lodash-es';

export default {
  computed: {
    ...mapGetters(['hasAccountFeature']),
    ...mapState(['globalStyle', 'universal']),
    getThemeColors() {
      const themeColors = _get(this.globalStyle.palette, 'themeColors', []);
      if (themeColors) {
        return themeColors.map(({ themeColor }) => themeColor);
      }
      return _get(this.globalStyle.palette, 'themeColors', []);
    },
    getThemeColorsRaw() {
      return _get(this.globalStyle.palette, 'themeColors');
    },
    getBrandColors() {
      const mainColor = _get(this.globalStyle.palette, 'mainColor');
      const secondaryColors = _get(this.globalStyle.palette, 'secondaryColors');
      const allColor = mainColor.concat(secondaryColors);
      return allColor;
    },
    getColors() {
      return this.getColorsMethod();
    },
    isUniversal() {
      return this.universal;
    },
  },
  methods: {
    getColorsMethod() {
      if (this.getThemeColors) {
        return [...window.om.template.palette, ...this.getThemeColors];
      }

      return [...window.om.template.palette];
    },
  },
};
