<template lang="pug">
.switch(:class="classes")
  input(
    :aria-checked="state"
    :id="`switch-${id}`"
    role="switch"
    type="checkbox"
    aria-disabled="false"
    :checked="state"
    :disabled="disabled"
    @click="switchState()"
  )
  .d-flex.switch-label(v-if="label")
    label.switch-label-container.cursor-pointer(:for="`switch-${id}`")
      span.switch-label-title {{ label }}
      om-tooltip.ml-2.switch-tooltip(v-if="tooltip") {{ tooltip }}
  .switch-wrapper
    .switch-false-title.switch-title(@click="switchState()" v-if="falseOption") {{ falseOption }}
    .switch-track(
      @click="switchState()"
      @mousedown="addFocus()"
      @mouseup="removeFocus()"
      :focus="focus"
    )
      .switch-thumb
    .switch-true-title.switch-title(@click="switchState()" v-if="trueOption") {{ trueOption }}
</template>
<script>
  import OmTooltip from '@/components/Elements/Tooltip/Tooltip.vue';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmSwitch',
    components: { OmTooltip },
    mixins: [designSystemMixin],
    model: {
      prop: 'value',
      event: 'switch',
    },
    props: {
      value: {
        type: Boolean,
      },
      label: {
        type: String,
        default: '',
      },
      tooltip: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: () => [],
      },
      labelPosition: {
        type: String,
        default: 'half',
        options: ['top', 'half', 'fill'],
        validator: (value) => {
          return ['top', 'half', 'fill'].includes(value.toLowerCase());
        },
      },
    },
    data() {
      return {
        focus: false,
      };
    },
    computed: {
      state: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('switch', v);
        },
      },
      falseOption() {
        return this.options[0];
      },
      trueOption() {
        return this.options[1];
      },
      classes() {
        return {
          'switch-position-top': this.labelPosition === 'top',
          'switch-position-half': this.labelPosition === 'half',
          'switch-position-fill': this.labelPosition === 'fill',
          'switch-has-label': this.label,
          'switch-has-options': this.options.length !== 0,
          'switch-has-tooltip': this.tooltip,
          'switch-is-focused': this.focus,
          'switch-is-active': this.state,
          'switch-disabled': this.disabled,
          'design-system': this.designSystem,
        };
      },
    },
    methods: {
      addFocus() {
        this.focus = true;
      },
      removeFocus() {
        this.focus = false;
      },
      switchState() {
        if (!this.disabled) {
          this.state = !this.state;
        }
      },
    },
  };
</script>
<style scoped lang="sass">
  @import '../../../sass/variables/_colors.sass'
  .switch
    position: relative
    display: flex

  .switch input[role=switch]
    visibility: hidden
    position: absolute
    opacity: 0
    width: 1px
    height: 1px

  .switch-label-container
    display: flex
    align-items: center

  .switch-label
    color: $om-gray-700
    font-size: 0.75rem
    line-height: 1.5rem

    .switch-has-label &
      flex: 0 0 50%
      max-width: 50%

  .switch-wrapper
    display: flex
    align-items: center

    .switch-has-label &
      flex: 0 0 50%
      max-width: 50%

  .switch-track
    width: 36px
    margin: 2px 0
    height: 20px
    border-radius: 10px
    background: $om-gray-50
    border: 1px solid $om-gray-400
    position: relative
    cursor: pointer
    .switch-is-active &
      background: $om-alert-green-500
      border-color: $om-alert-green-500
    .switch-has-options &
      margin: 2px 8px

    .switch-is-focused:hover &
      border: 1px solid $om-orange-200
      outline: 2px solid $om-orange-200

  .switch-title
    color: $om-gray-700
    font-size: 0.75rem
    cursor: pointer

  .switch-thumb
    background: $om-gray-500
    width: 14px
    height: 14px
    border-radius: 100%
    position: absolute
    left: 2px
    right: auto
    top: 2px
    bottom: auto
    transition: .3s cubic-bezier(.25,.8,.5,1)

    .switch-is-active &
      transform: translate(16px)
      background: $om-gray-50

  .switch-disabled:not(.switch-is-active)
    .switch-track
      background: $om-gray-200
    .switch-thumb
      background: $om-gray-400
  .switch-position-fill
    .switch-label
      flex: 2
      max-width: 100%
    .switch-wrapper
      flex: 1
    .switch-track
      margin-left: auto
</style>
