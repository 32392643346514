<template lang="pug">
.brand-link-element(:class="classes" v-bind="$attrs" v-on="$listeners")
  .brand-link-element-icon(v-if="withIconLeft")
    slot(name="left-icon")
      UilAngleLeftB(:size="autoIconSize")
  slot
  .brand-link-element-icon(v-if="withIconRight")
    slot(name="right-icon")
      UilAngleRightB(:size="autoIconSize")
</template>

<script>
  import { UilAngleLeftB, UilAngleRightB } from '@iconscout/vue-unicons';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmLink',
    components: { UilAngleLeftB, UilAngleRightB },
    mixins: [designSystemMixin],
    props: {
      primary: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      medium: {
        type: Boolean,
        default: false,
      },
      withIconLeft: {
        type: Boolean,
        default: false,
      },
      withIconRight: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      autoIconSize() {
        return this.small ? '0.875rem' : '1.5rem';
      },
      classes() {
        return {
          'brand-link-element-disabled': this.disabled,
          'brand-link-element-primary': this.primary,
          'brand-link-element-secondary': this.secondary,
          'brand-link-element-small': this.small,
          'brand-link-element-medium': this.medium,
          'brand-link-element-icon-left': this.withIconLeft,
          'brand-link-element-icon-right': this.withIconRight,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-link-element
    display: flex
    align-items: center
    cursor: pointer
    font-weight: 500
    font-size: 1rem
    @media screen and (min-width: 992px)
      font-size: 14px

    a
      &:hover
        color: inherit
        text-decoration: none !important

    &-icon-left
      &:focus
        padding-right: 4px

    &-icon-right
      &:focus
        padding-left: 4px

    &:focus
      border: 2px solid #F7B59F
      border-radius: 4px

    &-icon
      display: flex
      align-items: center

    &-primary
      color: #ed5a29

      &:hover
        color: #F17E58

    &-secondary
      color: #505763

      &:hover
        color: #272727

    &-medium
      font-size: 1rem

    &-small
      font-size: 0.875rem
    &-disabled
      pointer-events: none
      opacity: .5
      cursor: not-allowed
</style>
