<template lang="pug">
.brand-select-wrapper(:class="vertical ? 'col-12 col-md-6' : ''")
  .brand-select-card(
    :class="{ ...classes }"
    @click="$emit('click', $event)"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  )
    template(v-if="!multiple")
      .brand-select-card-svg(v-if="icon || $slots.iconContent")
        transition-svg-background
          template(slot="background" v-if="!backgroundOff")
            slot(name="backgroundContent")
              om-patches(:index="(index % 8) + 1")
          template(slot="icon")
            slot(name="iconContent")
              om-unique-icon(:name="icon")
      .d-flex.flex-column
        slot(name="title")
          .brand-select-card-title
            span(v-html="title")
            om-chip(v-if="chip" :color="chip.color")
              span {{ chip.upperCase ? $t(chip.text).toUpperCase() : $t(chip.text) }}
        slot(name="details")
          .brand-select-card-details(v-if="details && details.length" v-html="details")
      slot(name="arrow")
        chevron-right.brand-select-card-right(v-if="arrow" :color="arrowColor")
      inline-svg.brand-select-card-check(
        v-if="checked"
        :src="require('@/assets/admin/svg/icons/check-circle.svg')"
      )
      span.right-content(v-if="$slots.rightContent")
        slot(name="rightContent")
    template(v-else)
      .brand-select-card-check.d-flex
        input(type="checkbox" :checked="checked")
      .ml-3.d-flex.flex-column.flex-grow-1
        .brand-select-card-title(v-html="title")
        .brand-select-card-details(v-if="details && details.length" v-html="details")
      .brand-select-card-svg(v-if="icon || $slots.iconContent")
        transition-svg-background
          template(slot="background" v-if="!backgroundOff")
            om-patches(:index="(index % 8) + 1")
          template(slot="icon")
            slot(name="iconContent")
              om-unique-icon(:name="icon")
</template>

<script>
  import InlineSvg from 'vue-inline-svg';
  import OmPatches from '@/components/Elements/Patches/Patches.vue';
  import OmUniqueIcon from '@/components/Elements/UniqueIcon/UniqueIcon.vue';
  import TransitionSvgBackground from '@/components/Elements/SelectCards/TransitionSvgBackground.vue';
  import ChevronRight from '@/components/Svg/Icons/ChevronRight.vue';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmSelectCard',
    components: { TransitionSvgBackground, OmPatches, OmUniqueIcon, InlineSvg, ChevronRight },
    mixins: [designSystemMixin],
    props: {
      icon: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 1,
      },
      title: {
        type: String,
        default: '',
      },
      details: {
        type: String,
        default: '',
      },
      checked: {
        type: Boolean,
        default: false,
      },
      arrow: {
        type: Boolean,
        default: true,
      },
      arrowColor: {
        type: String,
        default: '#8F97A4',
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      backgroundOff: {
        type: Boolean,
        default: false,
      },
      chip: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        hovered: false,
      };
    },

    computed: {
      classes() {
        return {
          [`brand-select-card-${this.value}`]: true,
          'brand-select-card-iconless': !this.icon && !this.$slots.iconContent,
          'brand-select-card-checked': this.checked,
          'brand-select-card-with-details': this.details.length > 0,
          'brand-select-card-multiple': this.multiple,
          'design-system': this.designSystem,
          'select-card-small': this.small,
        };
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  @import '@/sass/components/_forms.sass'
  @import '@/sass/components/_checkbox.sass'
  .brand-select-wrapper
    margin-bottom: 0.75rem
    &.col-12
      margin-bottom: 1rem
    &:last-child
      margin-bottom: 0

  .brand-select-card
    position: relative
    display: flex
    align-items: center
    border-radius: 8px
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07)
    background-color: white
    height: 80px
    padding-left: 6.6875rem
    padding-right: 1.3125rem
    cursor: pointer
    border: 2px solid transparent

    &-title
      display: flex
      align-items: center
      .chip
        display: inline-block
        margin-left: 0.75rem
        padding: 0 0.25rem
        span
          font-weight: 700

    .right-content
      margin-left: auto

    &.select-card-small
      height: 4rem
      padding-left: 4rem

      svg
        height: 40px
        width: 40px

      .brand-select-card-title
        font-size: 1rem

      .brand-select-card-right
        height: 10px
        width: 6px
        transition: .6s
        margin-left: auto

      .brand-select-card-svg
        left: -0.9rem

    @media screen and (max-width: 575px)
      padding-left: 4.9375rem
      max-height: 56px

      svg
        height: 46px

    &-checked
      border: 2px solid #ED5A29

    &:hover
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15)

      .brand-select-card-right
        transform: translateX(6px)

    &:last-child
      margin-bottom: 0

    &-title
      font-size: 1.25rem
      line-height: 1.625rem
      font-weight: 500

      @media screen and (max-width: 575px)
        font-size: 16px
        line-height: 1.5

    &-details
      font-size: 12px
      line-height: 1.3
      color: $om-gray-600

    &-svg
      position: absolute
      left: 0.5rem

    .brand-select-card-right
      height: 14px
      width: 8px
      transition: .6s
      margin-left: auto

    &-check
      display: none

    &-with-details
      height: 100px

      .brand-select-card-title
        font-size: 1.25rem
        line-height: 1.75

      @media screen and (max-width: 1399px)
        height: 120px

    &-iconless
      padding-left: 1.3125rem

    &-multiple
      padding-left: 1.25rem
      padding-right: 1.25rem
      justify-content: space-between

      &.brand-select-card-with-details
        height: 80px

      .brand-select-card-check
        display: flex
        align-items: center

      .brand-select-card-svg
        position: relative
</style>
