<template lang="pug">
.autocomplete-wrapper(ref="autoCompleteWrapper")
  popper(:ref="`popper_${id}`" trigger="clickToOpen" :options="popperOptions")
    .popper.brand-popper.autocomplete-popper(v-show="renderedOptions.length")
      .autocomplete-options(:class="{ 'autocomplete-options-scroller': scrollerVisible }")
        .autocomplete-option(
          v-for="(option, index) in renderedOptions"
          :class="{ highlighted: highlightedIndex === index }"
          @click="onOptionClick(option)"
          @mouseenter="highlightedIndex = index"
          :ref="`autocomplete-option-${id}-${index}`"
        )
          slot(name="prepend-option" :option="option")
          .autocomplete-option-value.text-truncate(
            :class="`autocomplete-option-value-${id}-${index}`"
          )
            span {{ option }}
    template(slot="reference")
      .autocomplete-content.form-control(
        :class="{ 'form-control-lg': this.size === 'large', 'form-control-sm': this.size === 'small' }"
      )
        slot(name="autocomplete-input")
          input.text-left.autocomplete-input(
            ref="autoCompleteInput"
            autocomplete="off"
            type="text"
            :id="id"
            v-model="internalValue"
            :placeholder="placeholder"
            @keyup.enter="handleEnter"
            @keyup.down="handleArrow('down')"
            @keyup.up="handleArrow('up')"
          )
        .clearfix
</template>

<script>
  import Popper from 'vue-popperjs';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: {
      Popper,
    },
    mixins: [designSystemMixin],
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
      size: {
        type: String,
        default: 'medium',
        options: ['small', 'medium', 'large'],
        validator: (value) => {
          return ['small', 'medium', 'large'].includes(value.toLowerCase());
        },
      },
      placeholder: {
        type: String,
        default: '',
      },
      prefixes: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        filteredOptions: [],
        highlightedIndex: 0,
      };
    },
    computed: {
      popperOptions() {
        return {
          html: true,
          container: 'body',
          placement: 'bottom-start',
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: this.$refs.autoCompleteWrapper,
              fn(data) {
                const newData = data;
                const referenceWidth = newData.offsets.reference.width;
                const popperWidth = newData.offsets.popper.width;
                const widthDifferenceOfReferenceAndPopper = popperWidth - referenceWidth;
                const referenceRect = data.instance.reference.getBoundingClientRect();
                const referenceRightFromScreenSide = window.innerWidth - referenceRect.right;

                if (window.innerWidth <= 768) {
                  return data;
                }

                if (referenceWidth === popperWidth) {
                  return data;
                }

                if (referenceRightFromScreenSide > widthDifferenceOfReferenceAndPopper) {
                  return data;
                }

                // manually set position to bottom-end placement
                newData.offsets.popper.top = newData.offsets.reference.height;
                newData.offsets.popper.left = referenceWidth - popperWidth;
                return newData;
              },
              order: 850,
            },
          },
        };
      },
      internalValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      renderedOptions() {
        return this.internalValue?.length ? this.filteredOptions : this.options;
      },
      scrollerVisible() {
        return this.renderedOptions.length > 7;
      },
      popoverInstance() {
        return this.$refs[`popper_${this.id}`];
      },
    },
    watch: {
      renderedOptions() {
        this.highlightedIndex = 0;
      },
      internalValue(v) {
        this.filter(v);
      },
    },
    methods: {
      filter(v) {
        this.popoverInstance.showPopper = true;
        const { options } = this;
        let str = v.replace(/\*/g, '\\$&');
        if (this.prefixes.length) {
          for (const prefix of this.prefixes) {
            str = str.replace(prefix, '');
          }
        }
        const regex = new RegExp(str, 'i');

        const filteredArray = options.filter((option) => regex.test(option));

        this.filteredOptions = filteredArray;
        this.popoverInstance.updatePopper();
      },
      onOptionClick(option) {
        this.internalValue = option;
        this.$refs.autoCompleteInput.focus();
        this.$nextTick(() => {
          this.popoverInstance.doClose();
        });
      },
      handleEnter() {
        if (this.renderedOptions.length && this.popoverInstance?.showPopper) {
          this.internalValue = this.renderedOptions[this.highlightedIndex];
          this.$nextTick(() => {
            this.popoverInstance.doClose();
          });
        } else {
          this.$emit('enter');
        }
      },
      handleArrow(direction) {
        if (direction === 'down' && this.highlightedIndex < this.renderedOptions.length - 1) {
          this.highlightedIndex++;
        } else if (direction === 'up' && this.highlightedIndex > 0) {
          this.highlightedIndex--;
        }
        const scroller = document.querySelector('.autocomplete-options-scroller');
        if (scroller) {
          const highlighted = scroller.querySelector('.highlighted');
          if (highlighted) {
            scroller.scrollTo({
              top: highlighted.offsetTop - 40,
              behavior: 'smooth',
            });
          }
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import 'autocomplete.sass'
</style>
<style lang="sass" scoped>
  .popper.select-popper
    .popper__arrow
      display: none !important
</style>
