<template lang="pug">
.vc-editable-input.om-color
  span.vc-input__label {{ label }}
  .vc-editable-input-holder
    span.mr-1(v-if="label === 'hex'")
    input.vc-input__input(v-model="val" @keydown="handleKeyDown" @input="update" ref="input")
</template>

<script>
  export default {
    name: 'EditableInput',
    props: {
      changeKey: { type: String, default: undefined },
      label: String,
      desc: String,
      value: [String, Number],
      max: Number,
      min: Number,
      arrowOffset: {
        type: Number,
        default: 1,
      },
    },
    computed: {
      val: {
        get() {
          return this.value;
        },
        set(v) {
          // TODO: min
          if (!(this.max === undefined) && +v > this.max) {
            this.$refs.input.value = this.max;
          } else {
            return v;
          }
        },
      },
    },
    methods: {
      update(e) {
        this.handleChange(e.target.value);
      },
      handleChange(newVal) {
        const data = {};
        data[this.changeKey || this.label] = newVal;
        if (data.hex === undefined) {
          this.$emit('change', data);
        } else if (
          (data.hex[0] === '#' && [4, 7].includes(newVal.length)) ||
          (data.hex[0] !== '#' && [3, 6].includes(newVal.length))
        ) {
          this.$emit('change', data);
        }
      },
      handleKeyDown(e) {
        let val = this.val;
        const number = Number(val);

        if (number) {
          const amount = this.arrowOffset || 1;

          // Up
          if (e.keyCode === 38) {
            val = number + amount;
            this.handleChange(val);
            e.preventDefault();
          }

          // Down
          if (e.keyCode === 40) {
            val = number - amount;
            this.handleChange(val);
            e.preventDefault();
          }
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .vc-editable-input-holder
    display: flex
    align-items: center
    justify-content: space-between
    .vc-input__input
      min-width: 5rem
</style>
