<template lang="pug">
.ds-toast.d-flex(v-if="!isClosed" :class="classes")
  .ds-toast-sidebar(v-if="type === 'side'")
  .ds-toast-wrapper.d-flex.justify-center.align-items-center
    span(v-if="loading")
      lottie(filename="toast_loading_grey")
    slot(name="icon" v-if="!loading")
      component.ds-toast-icon-svg(v-if="icon" :is="iconComponent" :size="iconSize")
    .ds-toast-content.d-flex.mx-2(
      @click="onClickHandler"
      :style="{ cursor: onClick ? 'pointer' : '' }"
    )
      slot
    .ds-toast-separator(v-if="closable && allowSeparator")
    slot(name="close" v-if="closable && closeButtonPosition === 'center'")
      span.ds-toast-close(@click="close")
        uil-times(:width="24" :height="24" @click="close")
  slot(
    name="close"
    v-if="closable && (closeButtonPosition === 'top' || closeButtonPosition === 'bottom')"
  )
    span.ds-toast-close(
      :class="{ 'ds-toast-close-top': closeButtonPosition === 'top', 'ds-toast-close-bottom': closeButtonPosition === 'bottom' }"
      @click="close"
    )
      uil-times(:width="24" :height="24" @click="close")
</template>

<script>
  import {
    UilInfoCircle,
    UilTimes,
    UilExclamationTriangle,
    UilCheckCircle,
    UilExclamationCircle,
    UilMegaphone,
  } from '@iconscout/vue-unicons';
  import getUniconName from '@/components/Elements/mixins/getUniconName';
  import designSystemMixin from '@/components/Elements/mixins/designSystem';
  import Lottie from '@/components/Lottie.vue';

  export default {
    name: 'OmToast',
    components: {
      UilInfoCircle,
      UilTimes,
      UilExclamationTriangle,
      UilCheckCircle,
      UilExclamationCircle,
      UilMegaphone,
      Lottie,
    },
    mixins: [designSystemMixin],
    props: {
      color: {
        type: String,
        default: 'info',
        options: ['info', 'danger', 'success', 'darkInfo', 'warning', 'announcement'],
        validator: (value) => {
          return ['info', 'danger', 'success', 'darkInfo', 'warning', 'announcement'].includes(
            value,
          );
        },
      },
      closable: {
        type: Boolean,
        default: true,
      },
      closeButtonPosition: {
        type: String,
        default: 'center',
        options: ['top', 'center', 'bottom'],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      allowSeparator: {
        type: Boolean,
        default: true,
      },
      iconSize: {
        type: String,
        default: '20px',
      },
      type: {
        type: String,
        default: 'full',
        options: ['full', 'side', 'icon'],
        validator: (value) => {
          return ['full', 'side', 'icon'].includes(value);
        },
      },
      onClick: {
        type: Function,
        default: null,
      },
    },
    data: () => ({
      isClosed: false,
      icons: {
        info: 'info-circle',
        danger: 'exclamation-triangle',
        success: 'check-circle',
        darkInfo: 'exclamation-circle',
        warning: 'exclamation-circle',
        announcement: 'megaphone',
      },
    }),
    computed: {
      icon() {
        const color = this.color;
        return this.icons[color];
      },
      iconComponent() {
        return getUniconName(this.icon);
      },
      classes() {
        return {
          'design-system': this.designSystem,
          'ds-toast-side': this.type === 'side',
          'ds-toast-info': this.color === 'info' && this.type === 'full',
          'ds-toast-danger': this.color === 'danger' && this.type === 'full',
          'ds-toast-success': this.color === 'success' && this.type === 'full',
          'ds-toast-darkInfo': this.color === 'darkInfo' && this.type === 'full',
          'ds-toast-warning': this.color === 'warning' && this.type === 'full',
          'ds-toast-side-info':
            (this.color === 'info' || this.color === 'darkInfo') && this.type === 'side',
          'ds-toast-side-danger': this.color === 'danger' && this.type === 'side',
          'ds-toast-side-success': this.color === 'success' && this.type === 'side',
          'ds-toast-side-warning': this.color === 'warning' && this.type === 'side',
          'ds-toast-side-announcement': this.color === 'announcement' && this.type === 'side',
          'ds-toast-icon': this.type === 'icon',
          'ds-toast-icon-info': this.color === 'info' && this.type === 'icon',
          'ds-toast-icon-darkInfo': this.color === 'darkInfo' && this.type === 'icon',
          'ds-toast-icon-warning': this.color === 'warning' && this.type === 'icon',
          'ds-toast-icon-danger': this.color === 'danger' && this.type === 'icon',
          'ds-toast-icon-success': this.color === 'success' && this.type === 'icon',
        };
      },
    },
    methods: {
      close() {
        this.isClosed = true;

        this.$emit('close');
      },
      onClickHandler() {
        if (this.onClick) {
          this.onClick();
          this.close();
        }
      },
    },
  };
</script>

<style lang="sass">
  @import 'toast.sass'
</style>
