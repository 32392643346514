<template lang="pug">
.brand-color-palette
  color-box(
    v-for="color in palette"
    :key="color"
    :color="color"
    @click.native="$emit('selected', color)"
  )
</template>

<script>
  import ColorBox from './ColorBox.vue';

  export default {
    components: { ColorBox },

    props: {
      palette: {
        type: Array,
        default: () => [
          '#D0021B',
          '#D59412',
          '#E0D31F',
          '#8B572A',
          '#7ED321',
          '#417505',
          '#BD10E0',
          '#7108D0',
          '#4A90E2',
          '#50E3C2',
          '#0D379D',
          '#000000',
          '#4A4A4A',
          '#9B9B9B',
        ],
      },
    },
  };
</script>

<style lang="sass">
  .brand-color-palette
    display: flex
    flex-wrap: wrap
    margin-left: -5px
    margin-right: -5px
    margin-top: 1.25rem
    margin-bottom: 2.5rem

    .brand-color-box
      margin-left: 5px
      margin-right: 5px
      margin-bottom: 0.625rem
      cursor: pointer
      box-shadow: 0 0 0px 1px rgba(0,0,0,0.1)
</style>
