<template lang="pug">
.row
  .col-8.offset-2
    .segment-empty-content.d-flex.flex-column.justify-content-center
      img.segment-empty-content-image.mt-4(:src="require('@/assets/admin/svg/monk-save.svg')")
      om-body-text.text-center.mt-4(bt700sm) {{ text }}
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="sass">
  .segment-empty-content
    &-image
      max-height: 10.75rem
</style>
