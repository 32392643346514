<template lang="pug">
.unique-icon(:style="styles" :class="classes")
  inline-svg(:src="require(`./svgs/${name}.svg`)" :width="size" :height="size")
</template>

<script>
  import InlineSvg from 'vue-inline-svg';
  import designSystemMixin from '../mixins/designSystem';

  const iconSizes = ['100%', 32, 48, 52, 56, 60];
  export default {
    name: 'OnUniqueIcon',
    components: { InlineSvg },
    mixins: [designSystemMixin],
    props: {
      name: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: '#ED5A29',
      },
      size: {
        type: [Number, String],
        default: 52,
        options: iconSizes,
        validator: (value) => {
          return iconSizes.includes(value);
        },
      },
    },

    computed: {
      styles() {
        return {
          color: this.color,
        };
      },
      classes() {
        return {
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>
