<template lang="pug">
.input-group.flex-column.datetime(:class="classes")
  label.form-label(:for="id" v-if="label") {{ label }}
  .datetime-toggle(v-if="customLabels")
    .flatpickr
      a.datetime-toggle-text(data-toggle) {{ customLabel }}
      input(type="text" ref="flatpickrInput" data-input :class="formClasses" readonly)
  .datetime-content(v-else)
    input.form-control.flatpickr(
      ref="flatpickrInput"
      :id="id"
      :disabled="disabled"
      :placeholder="placeholder"
      readonly
      :class="formClasses"
      type="text"
    )
    .input-group-append
      span.input-group-text
        UilCalender(slot="icon" size="1.25rem")
  .form-text.text-danger(v-if="errorMessage && error")
    slot(name="error") {{ errorMessage }}
</template>
<script>
  import { UilCalender } from '@iconscout/vue-unicons';
  import { Hungarian } from 'flatpickr/dist/l10n/hu';
  import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';
  import moment from 'moment';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: {
      UilCalender,
    },
    mixins: [designSystemMixin],
    props: {
      model: {
        prop: 'value',
        event: 'datetime',
      },
      value: {
        type: [String, Array, Date, Number],
      },
      id: {
        type: String,
        required: true,
      },
      locale: {
        default: 'en',
        options: ['en', 'hu'],
        validator: (value) => {
          return ['en', 'hu'].includes(value.toLowerCase());
        },
      },
      customLabels: {
        type: Object,
        default: null,
      },
      mode: {
        default: 'single',
        options: ['single', 'range'],
        validator: (value) => {
          return ['single', 'range'].includes(value.toLowerCase());
        },
      },
      label: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      showClear: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'normal',
        options: ['small', 'normal'],
        validator: (value) => {
          return ['small', 'normal'].includes(value.toLowerCase());
        },
      },
      minDate: {
        type: [String, Date, Number],
        default: null,
      },
      maxDate: {
        type: [String, Date, Number],
        default: null,
      },
    },
    data() {
      return {
        inputFormat: {
          en: 'm/d/Y',
          hu: 'Y/m/d',
        },
        placeholderFormat: {
          en: 'MM/DD/YYYY',
          hu: 'YYYY/MM/DD',
        },
        flatpickrInstance: null,
        innerDate: null,
        clear: false,
        clearText: {
          hu: 'Törlés',
          en: 'Clear',
        },
      };
    },
    computed: {
      customLabel() {
        const locale = this.locale;
        return this.customLabels[locale];
      },
      date: {
        get() {
          return this.value;
        },
        set(v) {
          this.innerDate = v;
          this.$emit('datetime', v);
        },
      },
      placeholder() {
        return this.locale === 'en' ? this.placeholderFormat.en : this.placeholderFormat.hu;
      },
      format() {
        return this.locale === 'en' ? this.inputFormat.en : this.inputFormat.hu;
      },
      formClasses() {
        return {
          'form-control-sm': this.size === 'small',
          'is-invalid': this.error,
        };
      },
      classes() {
        return {
          'datetime-locale-hu': this.locale === 'hu',
          'datetime-locale-en': this.locale === 'en',
          'datetime-range': this.mode === 'range',
          'datetime-single': this.mode === 'single',
          'datetime-error': this.error,
          'datetime-disabled': this.disabled,
          'datetime-showClear': this.showClear,
          'datetime-with-minDate': this.minDate,
          'datetime-with-maxDate': this.maxDate,
          'datetime-sm': this.size === 'small',
          'design-system': this.designSystem,
        };
      },
    },
    watch: {
      date: {
        handler(newValue, oldValue) {
          this.innerDate = newValue;
        },
      },
    },
    mounted() {
      this.innerDate = this.value;
      setTimeout(() => {
        this.initFlatpickr();
      }, 400);
    },
    beforeDestroy() {
      if (this.flatpickrInstance !== null) {
        this.flatpickrInstance.close();
        this.flatpickrInstance.destroy();
      }
    },
    methods: {
      async initFlatpickr() {
        const updateDate = (dates) => {
          // in single date mode
          if (this.mode === 'single') {
            this.date = dates[0];
          }

          // in range date mode
          if (this.mode === 'range') {
            if (dates.length > 1) {
              this.date = [dates[0], dates[1]];
            }
            // if click to clear Btn via ShortcutButtonsPlugin, set to null date and emit
            if (this.clear) {
              this.date = [null, null];
            }
          }
        };
        // set to valid range if user choose only one date and close flatpickr
        const setValidRange = (dates) => {
          if (!this.clear && this.mode === 'range' && dates.length !== 2) {
            this.flatpickrInstance.setDate(this.innerDate);
          }
        };

        const [{ default: flatpickr }] = await Promise.all([
          import('flatpickr'),
          import('flatpickr/dist/flatpickr.min.css'),
        ]);

        const locale = this.locale === 'en' ? 'default' : Hungarian;
        let defaultDate = this.innerDate;
        let minimumDate = JSON.parse(JSON.stringify(this.minDate));
        const maxDate = this.maxDate;

        const plugins = [];

        if (this.showClear) {
          plugins.push(
            new ShortcutButtonsPlugin({
              button: {
                label: this.locale === 'en' ? this.clearText.en : this.clearText.hu,
              },
              onClick: (index, fp) => {
                this.clear = true;
                fp.clear();
                fp.close();
                this.clear = false;
              },
            }),
          );
        }

        let minimumDateObject = minimumDate;
        if (defaultDate === 'today') {
          defaultDate = new Date();
        }

        if (minimumDate === 'today') {
          minimumDateObject = new Date();
        }

        let disabledDays = [];

        if (minimumDate) {
          const minimumDateTimeStamp = moment(minimumDateObject).valueOf();
          const defaultDateTimeStamp = moment(defaultDate).valueOf();

          // Disabling range(s) of dates to Allow date less than minDate in flatpickr
          // https://stackoverflow.com/questions/66455580/allow-date-less-than-mindate-in-flatpickr
          if (minimumDateTimeStamp > defaultDateTimeStamp) {
            minimumDate = null; // disable minDate prop to work with disabled ranges

            const selectedDatePlusOne = moment(defaultDate).startOf('day').add(1, 'd').toDate();
            const selectedDateMinusOne = moment(defaultDate)
              .startOf('day')
              .subtract(1, 'd')
              .toDate();
            const minimumDateMinusOne = moment(minimumDateObject)
              .startOf('day')
              .subtract(1, 'd')
              .toDate();

            const baseDate = new Date(0);

            disabledDays = [
              {
                from: baseDate,
                to: selectedDateMinusOne,
              },
              {
                from: selectedDatePlusOne,
                to: minimumDateMinusOne,
              },
            ];
          }
        }

        this.flatpickrInstance = flatpickr(this.$refs.flatpickrInput, {
          noCalendar: false,
          enableTime: false,
          mode: this.mode,
          defaultDate,
          locale,
          maxDate,
          minDate: minimumDate,
          dateFormat: this.format,
          onValueUpdate: updateDate,
          onChange: updateDate,
          onClose: setValidRange,
          plugins,
          disable: disabledDays,
        });
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'datetime.sass'
</style>
<style lang="sass">
  @import '../../../sass/variables/variables'
  .flatpickr-calendar
    font-size: 12px!important

    &.open
      z-index: 999999!important

    &:before,
    &:after
      display: none!important

    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay
      background: $om-orange-500
      border-color: $om-orange-500
    .flatpickr-day.inRange
      box-shadow: -5px 0 0 $om-orange-200, 5px 0 0 $om-orange-200
    .flatpickr-day.inRange,
    .flatpickr-day.prevMonthDay.inRange,
    .flatpickr-day.nextMonthDay.inRange,
    .flatpickr-day.today.inRange,
    .flatpickr-day.prevMonthDay.today.inRange,
    .flatpickr-day.nextMonthDay.today.inRange,
    .flatpickr-day:hover,
    .flatpickr-day.prevMonthDay:hover,
    .flatpickr-day.nextMonthDay:hover,
    .flatpickr-day:focus,
    .flatpickr-day.prevMonthDay:focus,
    .flatpickr-day.nextMonthDay:focus
      background: $om-orange-200
      border-color: $om-orange-200

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
    .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))
      box-shadow: none

    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month
      display: flex
    span.flatpickr-weekday
      color:$om-gray-700

    .flatpickr-day.flatpickr-disabled,
    .flatpickr-day.flatpickr-disabled:hover
      color: rgba($om-gray-700, .2)
    .shortcut-buttons-flatpickr-buttons
      text-align: right
      margin: 1rem 0
    .shortcut-buttons-flatpickr-button
      background: transparent
      border: none
      font-size: 0.85rem
      color: $om-gray-700
      &:hover
        color: $om-orange-500
</style>
