<template lang="pug">
.vc-hue(:class="hueClass")
  .vc-hue-container(
    ref="container"
    @mousedown="handleMouseDown"
    @touchmove="handleChange"
    @touchstart="handleChange"
  )
    .vc-hue-pointer(:style="position")
      .vc-hue-picker
</template>

<script>
  export default {
    name: 'Hue',
    props: {
      value: Object,
      direction: {
        type: String,
        default: 'horizontal', // [horizontal | vertical]
      },
    },
    data() {
      return {
        pullDirection: '',
      };
    },
    computed: {
      top() {
        return this.direction === 'horizontal' ? 0 : `${-((this.getHue * 100) / 360) + 100}%`;
      },
      left() {
        return this.direction === 'horizontal' ? (this.getHue * 100) / 360 : 0;
      },
      position() {
        return { top: `${this.top}%`, left: `${this.left}%` };
      },
      getHue() {
        return this.value.hsl.h || 0;
      },
      hueClass() {
        return `vc-hue--${this.direction}`;
      },
    },
    watch: {
      colors(n, o) {
        if (n.hsl.h !== 0 && n.hsl.h - o.hsl.h > 0) this.pullDirection = 'right';
        else if (n.hsl.h !== 0 && n.hsl.h - o.hsl.h < 0) this.pullDirection = 'left';
      },
    },
    methods: {
      handleChange(e, skip) {
        if (!skip) e.preventDefault();

        const container = this.$refs.container;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;

        const xOffset = container.getBoundingClientRect().left + window.pageXOffset;
        const yOffset = container.getBoundingClientRect().top + window.pageYOffset;
        const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
        const pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
        const left = pageX - xOffset;
        const top = pageY - yOffset;

        let h;
        let percent;

        if (this.direction === 'vertical') {
          if (top < 0) {
            h = 360;
          } else if (top > containerHeight) {
            h = 0;
          } else {
            percent = -((top * 100) / containerHeight) + 100;
            h = (360 * percent) / 100;
          }
        } else if (left < 0) {
          h = 0;
        } else if (left > containerWidth) {
          h = 360;
        } else {
          percent = (left * 100) / containerWidth;
          h = (360 * percent) / 100;
        }

        if (this.value.hsl.h !== h) {
          this.$emit('change', {
            h,
            s: this.value.hsl.s,
            l: this.value.hsl.l,
            a: this.value.hsl.a,
            source: 'hsl',
          });
        }
      },
      handleMouseDown(e) {
        this.handleChange(e, true);
        window.addEventListener('mousemove', this.handleChange);
        window.addEventListener('mouseup', this.handleMouseUp);
      },
      handleMouseUp() {
        this.unbindEventListeners();
      },
      unbindEventListeners() {
        window.removeEventListener('mousemove', this.handleChange);
        window.removeEventListener('mouseup', this.handleMouseUp);
      },
    },
  };
</script>
