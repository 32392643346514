<template lang="pug">
span
  div
    upload-area.vc-upload-area(
      :src="backgroundSrc"
      :editMobile="editMobile"
      @click="imageManager"
      @remove="resetMobileImage"
      :inColorPicker="true"
    )
  .vc-sketch-top
    .vc-sketch-section.mt-2
      .vc-sketch-section--title {{ $t('image') }}
      .mt-2
        image-position-select(
          label="position"
          :property="imagePositionPath"
          :options="imagePositions"
          :typeOfComponent="typeOfComponent"
        )
        image-position-select.mt-3(
          label="repeat"
          :property="imageRepeatPath"
          :options="imageRepeat"
          :typeOfComponent="typeOfComponent"
        )
    .vc-sketch-section.mt-3(v-if="['contain', 'cover'].includes(selectedImagePosition)")
      .vc-sketch-section--title {{ $t('alignment') }}
      .d-flex
        align-select.mt-2.mr-5.aligning(
          label="horizontal"
          :property="horizontalAlignPath"
          :options="imageHorizontalAlign.map(({ key }) => key)"
          :vertical="true"
          :inline="false"
          :small="true"
          :showOption="true"
          :editMobile="true"
        )
        align-select.mt-2.aligning(
          label="vertical"
          :property="selectedImagePosition === 'contain' ? imageAlignPath : imageVerticalAlign"
          :options="imageAlign.map(({ key }) => key)"
          :inline="false"
          :small="true"
          :showOption="true"
          :editMobile="true"
        )
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import UploadArea from '@/editor/components/UploadArea';
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';
  import ImagePositionSelect from '../common/ImagePositionSelect.vue';
  import ImageAlignSelect from '../common/ImageAlignSelect.vue';

  export default {
    name: 'ImageTab',
    components: {
      UploadArea,
      ImagePositionSelect,
      ImageAlignSelect,
      AlignSelect,
    },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      propsProperty: { type: String, required: true },
      isGlobalSubPath: { type: Boolean, default: false },
      subPath: { type: String, default: '' },
      saveImageUrl: { type: Boolean, default: false },
      blacklist: { type: Array, default: () => [] },
      i18n: { type: Boolean, default: true },
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        canEditMobile: true,
        imagePositions: [
          { key: 'original', value: 'full' },
          { key: 'contain', value: 'contain' },
          { key: 'cover', value: 'cover' },
          { key: 'stretch', value: '100% 100%' },
        ],
        imageAlign: [
          { key: 'top', value: 'top' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
        imageHorizontalAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        imageRepeat: [
          { key: 'noRepeat', value: 'no-repeat' },
          { key: 'repeat', value: 'repeat' },
          { key: 'repeatHorizontally', value: 'repeat-x' },
          { key: 'repeatVertically', value: 'repeat-y' },
        ],
      };
    },
    computed: {
      ...mapState(['globalStyle', 'images', 'mobilePreview']),
      backgroundSrc() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageId`
          : this.getPath('background.imageId');

        const imageId = this.getValueOf(path);
        const image = this.images.find((i) => i._id === imageId);
        return image ? image.url : this.savedImageUrl;
      },
      savedImageUrl() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageUrl`
          : this.getPath('background.imageUrl');
        return this.getValueOf(path);
      },
      selectedImagePosition() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imagePosition`
          : this.getPath('background.imagePosition');
        return this.getValueOf(path);
      },
      imagePositionPath() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imagePosition`
          : this.getPath('background.imagePosition');
        return path;
      },
      imageRepeatPath() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageRepeat`
          : this.getPath('background.imageRepeat');
        return path;
      },
      horizontalAlignPath() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageHorizontalAlign`
          : this.getPath('background.imageHorizontalAlign');
        return path;
      },
      imageAlignPath() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageAlign`
          : this.getPath('background.imageAlign');
        return path;
      },
      imageVerticalAlign() {
        const path = this.property.includes('globalStyle')
          ? `${this.property}.imageVerticalAlign`
          : this.getPath('background.imageVerticalAlign');
        return path;
      },
    },
    methods: {
      ...mapMutations([
        'updateStyle',
        'showImageManager',
        'setImageManagerDestination',
        'setSelectedPath',
      ]),
      imageManager() {
        this.setImageManagerDestination({
          targetAttr: this.getTargetAttr(),
          addElement: false,
          background: true,
          saveImageUrl: this.saveImageUrl,
          extBlacklist: this.blacklist,
          editedDevice: this.editedDeviceType,
        });
        this.setSelectedPath(this.subPath);
        this.showImageManager(true);
      },
      resetMobileImage() {
        this.setValueOf('mobile.background.imageId', null, true);
      },
      getTargetAttr() {
        if (this.subPath.includes('subElements')) {
          return `${this.propsProperty}.${this.subPath}`;
        }
        return this.propsProperty.includes('globalStyle')
          ? this.propsProperty.replace('.background', '')
          : this.propsProperty;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .vc-sketch-section--title
    font-family: Roboto
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 16px
    color: #505763
  .aligning
    font-size: 12px
    line-height: 16px
    color: #505763
    flex: 1
</style>
