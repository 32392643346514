<template lang="pug">
quill-editor.om-quill-editor(ref="editor" :options="quillOptions" v-bind="$attrs" v-on="$listeners")
</template>

<script>
  import { quillEditor } from 'vue-quill-editor';
  import { validateUrl } from '@/util';
  import modules from './modules';

  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';

  export default {
    components: { quillEditor },

    mixins: [modules],

    props: {
      simple: {
        type: Boolean,
        default: true,
      },
      detailed: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      editor() {
        return this.$refs.editor.quill;
      },
      quillOptions() {
        const toolbar = this.detailed
          ? [
              { size: ['small', false, 'large', 'huge'] },
              'bold',
              'italic',
              'underline',
              'strike',
              { align: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'image',
              'clean',
            ]
          : [['bold', 'italic', 'underline', 'strike', { align: [] }, 'link', 'clean']];

        return {
          placeholder: '',
          modules: {
            toolbar,
            imageResize: {
              modules: ['Resize', 'DisplaySize'],
            },
          },
        };
      },
    },

    mounted() {
      this.addLinkValidation(this.editor);
    },

    methods: {
      addLinkValidation(editor) {
        const originalSave = editor.theme.tooltip.save;

        editor.theme.tooltip.save = function () {
          if (!this.textbox.value.includes('http')) {
            this.textbox.value = `http://${this.textbox.value}`;
          }

          if (validateUrl(this.textbox.value)) {
            this.textbox.classList.remove('invalid');
            // eslint-disable-next-line
            originalSave.apply(this, arguments);
          } else {
            this.textbox.classList.add('invalid');
          }
        };
      },
    },
  };
</script>

<style lang="sass">
  .om-quill-editor
    p,
    ul,
    ol
      margin-bottom: 1rem

    a
      color: #ed5a29 !important
      text-decoration: underline !important

    img
      display: inline-block

    .ql-editor
      background-color: white
</style>
