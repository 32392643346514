<template lang="pug">
.checkbox.form-check(:class="classes")
  input.form-check-input(
    ref="checkbox"
    :class="{ 'is-invalid': error }"
    type="checkbox"
    :value="value"
    :checked="value"
    :disabled="disabled"
    :id="id"
    @click="switchState()"
    @mousedown="addFocus()"
    @mouseup="removeFocus()"
    :focus="focus"
  )
  label.form-check-label(:for="id" @mousedown="addFocus()" @mouseup="removeFocus()") {{ label }}
</template>
<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmCheckbox',
    mixins: [designSystemMixin],
    props: {
      id: {
        type: String,
        required: true,
      },
      value: {
        type: Boolean,
        default: false,
      },
      canIndeterminate: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        focus: false,
      };
    },
    computed: {
      state: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      classes() {
        return {
          'checkbox-is-focused': this.focus,
          'checkbox-indeterminate': this.canIndeterminate,
          'checkbox-disabled': this.disabled,
          'checkbox-error': this.error,
          'design-system': this.designSystem,
        };
      },
    },
    mounted() {
      if (this.canIndeterminate) {
        this.$refs.checkbox.indeterminate = true;
      }
    },
    methods: {
      switchState() {
        if (!this.disabled) {
          this.state = !this.state;
        }
      },
      addFocus() {
        this.focus = true;
      },
      removeFocus() {
        this.focus = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  @import '@/sass/components/_forms.sass'
  @import '@/sass/components/_checkbox.sass'
</style>
