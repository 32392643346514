<template lang="pug">
om-modal(
  name="segments-user-choose-modal"
  @beforeOpen="onBeforeOpen"
  @beforeClose="onBeforeClose"
  width="800px"
  scrollable
)
  template(#modal-header)
    .row
      .col
        transition(name="fade")
          om-heading(h5)(v-if="!stepChange") {{ modalTitle }}
    .brand-modal-action-icon
      span#choose-segment-modal-close.cursor-pointer(
        @click="$modal.hide('segments-user-choose-modal')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(#modal-body)
    .choose-segment-modal-content(v-if="!loading")
      transition(name="fade")
        .choose-segment-modal-select(v-if="isFirstStep && !stepChange && !loading")
          om-pills(:pills="pills" :selected="tab" @select="tab = $event" key)
          .mt-3
            transition(name="fade")
              .choose-segment-tab-content(
                v-if="!tabChange"
                :class="{ 'justify-content-center': contentItems && !contentItems.length }"
              )
                SegmentsList(
                  v-if="tab !== 'copy'"
                  :showCustom="tab === 'predefined'"
                  :items="contentItems"
                  :selected="selected"
                  @select="selected = $event"
                  :tab="tab"
                  :dataLoading="loading"
                )
                CampaignList(
                  v-else
                  :selected="selected"
                  @select="onCampaignSelect"
                  @isEmptyContent="isEmptyContent = $event"
                )
        UserSegmentSummary(
          ref="summary"
          v-if="isSecondStep && !tabChange && !loading"
          :domain="domain"
          :data="selectedData"
          :campaignVersion="campaignVersion"
          :campaignType="campaignType"
          :personalizedRules="personalizedRules"
        )
  template(slot="modal-footer")
    .d-flex.justify-content-end(v-if="!loading")
      om-button#choose-segment-modal-back(
        v-if="isSecondStep"
        :disabled="!selected"
        @click="step -= 1"
      ) {{ $t('back') }}
      om-button#choose-segment-modal-next(
        primary
        v-if="!isEmptyContent && tab"
        :disabled="!selected"
        @click="next"
      ) {{ $t(showSummary ? 'next' : 'done') }}
      om-button#save-segment-modal-cancel(
        v-if="isEmptyContent && tab"
        @click="$modal.hide('segments-user-choose-modal')"
      ) {{ $t('cancel') }}
</template>

<script>
  import SEGMENTS from '@/graphql/Segments.gql';
  import { OmPills } from '@/components/Elements';
  import SegmentsList from '@/components/Segments/SegmentsList.vue';
  import CampaignList from '@/components/Segments/CampaignList.vue';
  import UserSegmentSummary from '@/components/Modals/Segments/UserSegmentSummary.vue';
  import { mapGetters } from 'vuex';
  import { track } from '@/services/xray';
  import rulesFilterMixin from './rulesFilterMixin';

  export default {
    components: { SegmentsList, CampaignList, OmPills, UserSegmentSummary },
    mixins: [rulesFilterMixin],
    props: {
      campaignSegments: { type: Object, default: () => ({}) },
      campaignVersion: { type: Number, default: null },
    },
    data: () => ({
      tab: null,
      selected: null,
      campaign: null,
      tabChange: false,
      step: 0,
      stepChange: false,
      domain: null,
      predefined: [],
      userdefined: [],
      isEmptyContent: false,
      preselect: false,
      loading: true,
    }),
    apollo: {
      predefined: {
        query: SEGMENTS,
        variables: { type: 'predefined' },
        update: (data) => data.segments,
      },
      userdefined: {
        query: SEGMENTS,
        variables: { type: 'userdefined' },
        update: (data) => data.segments,
      },
    },
    computed: {
      ...mapGetters(['isShopifyDomain', 'isKlaviyoDomain']),
      tabs() {
        const tabs = ['userdefined', 'copy'];
        if (!this.isEmbedded && !this.isDynamicContent) {
          tabs.unshift('predefined');
        }

        return tabs;
      },
      shopifyDomain() {
        return this.isShopifyDomain(this.domain);
      },
      klaviyoDomain() {
        return this.isKlaviyoDomain(this.domain);
      },
      isFirstStep() {
        return this.step === 0;
      },
      isSecondStep() {
        return this.step === 1;
      },
      selectedData() {
        const selected = this.findSelected();
        if (!selected && !this.campaign) return;
        return {
          name: this.campaign ? this.campaign.name : selected.name[this.$i18n.locale],
          rules: this.campaign ? this.campaign.settings.frontendRules : selected.frontendRules,
        };
      },
      rules() {
        return this.selectedData?.rules || [];
      },
      modalTitle() {
        return !this.step
          ? this.$t('userSegment.modals.choose.title')
          : this.$t('userSegment.modals.summary.title');
      },
      pills() {
        return this.tabs.map((key) => ({
          key,
          text: this.$t(`userSegment.modals.choose.tabs.${key}.title`),
        }));
      },
      contentItems() {
        if (this.tab && this.tab !== 'copy') {
          return this[this.tab].filter(({ showFor }) => {
            if (!showFor) return true;
            return this.shopifyDomain
              ? ['shopify', 'all'].includes(showFor)
              : ['non-shopify', 'all'].includes(showFor);
          });
        }
        return [];
      },
      showSummary() {
        return this.tab !== 'predefined' || this.filteredRules.personalized?.length;
      },
    },
    watch: {
      contentItems(v) {
        this.isEmptyContent = !v.length;
      },
      tab() {
        this.selected = null;
        this.campaign = null;
        this.tabChange = true;
        this.preselectPredefinedSegment();
        setTimeout(() => {
          this.tabChange = false;
        }, 300);
      },
      step() {
        this.tabChange = true;
        this.stepChange = true;
        setTimeout(() => {
          this.tabChange = false;
          this.stepChange = false;
        }, 300);
      },
    },
    methods: {
      async onBeforeOpen(e) {
        this.loading = true;
        await this.$apollo.queries.predefined.refetch();
        await this.$apollo.queries.userdefined.refetch();
        this.step = 0;
        this.tab = 'predefined';
        this.selected = null;
        this.domain = e.params.domain;
        this.tab = this.tabs[0];
        this.preselect = e.params.preselect;
        this.loading = false;
      },
      onBeforeClose() {
        this.step = 0;
        this.tab = null;
        this.selected = null;
        this.domain = null;
      },
      next() {
        if (!this.step && this.showSummary) return this.step++;
        this.update();
      },
      getApplicableRules() {
        if (this.selected === 'custom') return [];
        const applicable =
          this.tab === 'predefined'
            ? this.filteredRules.applicable
            : this.$refs.summary.filteredRules.applicable;

        return [...applicable, ...this.filteredRules.personalized];
      },
      update() {
        this.trackLoad();
        this.$emit('updateFrontendRules', this.getApplicableRules());
      },
      trackLoad() {
        const properties = { type: this.tab, selected: this.selected };
        if (this.selected !== 'custom') {
          properties.name = this.selectedData.name;
        }
        track('segment:load', properties);
      },
      findSelected() {
        return this.campaign ? null : this[this.tab].find(({ _id }) => _id === this.selected);
      },
      onCampaignSelect(campaign) {
        this.campaign = campaign || null;
        this.selected = campaign?._id;
      },
      preselectPredefinedSegment() {
        if (!this.preselect || this.tab !== 'predefined') return;
        this.preselect = false;
        const { shopifySegment = null, nonShopifySegment = null } = this.campaignSegments;
        if (this.shopifyDomain) {
          this.selected = shopifySegment || nonShopifySegment;
          return;
        }
        this.selected = nonShopifySegment;
      },
    },
  };
</script>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'
  div[data-modal="segments-user-choose-modal"]
    .v--modal-box.v--modal
      min-height: 25rem
  .choose-segment
    &-modal-content
      min-height: 15rem
    &-item-inner
      border: 1px solid $om-gray-400
      border-radius: 8px
      width: 100%
      box-sizing: border-box
      color: $om-gray-700
      &.active
        border: 1px solid $om-orange-500
        outline: 1px solid $om-orange-500
      &-image
        object-fit: contain
        height: 4rem
        width: 4rem
</style>
