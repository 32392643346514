<template lang="pug">
.input-group.flex-column.timepicker(:class="classes")
  label.form-label(:for="id" v-if="label") {{ label }}
  popper(
    trigger="click"
    :disabled="disabled"
    :options="{ placement: 'bottom-start' }"
    @created="popoverInstance = $event"
    @hide="sendInputTime()"
  )
    .popper.timepicker-popper
      .row.no-gutters
        .col.px-1.timepicker-dots
          spinner-input#hours(
            :value.sync="hour"
            editable
            @setValue="modifyHours($event)"
            @up="setHours(1)"
            @down="setHours(-1)"
          )
        .col.px-1
          spinner-input#minutes(
            v-model="minute"
            editable
            @setValue="modifyMinutes($event)"
            @up="setMinutes(1)"
            @down="setMinutes(-1)"
          )
        .col.px-1(v-if="locale === 'en'")
          spinner-input#partOfDay(
            :value.sync="partOfDay"
            @up="setPartOfDay(12)"
            @down="setPartOfDay(-12)"
          )
      .row.no-gutters(v-if="clear")
        .col-12
          hr.mt-0
      .row.no-gutters(v-if="clear")
        .col-12.d-flex.justify-content-end
          a.clear-time(@click="clearTime()") {{ clear }}
    .reference(slot="reference")
      .timepicker-content
        input.form-control(
          :id="id"
          :disabled="disabled"
          :placeholder="placeholder"
          readonly
          :class="formClasses"
          type="text"
          :value="internalTimeObject ? internalTimeObject.format(timeFormat) : ''"
        )
        .input-group-append
          span.input-group-text
            UilClockEight(slot="icon" size="1.25rem")
  .form-text.text-danger(v-if="errorMessage && error")
    slot(name="error") {{ errorMessage }}
</template>
<script>
  import { UilClockEight } from '@iconscout/vue-unicons';
  import Popper from 'vue-popperjs';
  import moment from 'moment';
  import SpinnerInput from '../SpinnerInput';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: { Popper, UilClockEight, SpinnerInput },
    mixins: [designSystemMixin],
    props: {
      model: {
        prop: 'value',
        event: 'change',
      },
      value: {
        type: [Date, Object],
      },
      label: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'normal',
        options: ['small', 'normal'],
        validator: (value) => {
          return ['small', 'normal'].includes(value.toLowerCase());
        },
      },
      locale: {
        default: 'en',
        options: ['en', 'hu'],
        validator: (value) => {
          return ['en', 'hu'].includes(value.toLowerCase());
        },
      },
    },
    data() {
      return {
        popoverInstance: null,
        defaultTime: new Date('2020-01-01 12:00 AM'),
        internalTimeObject: null,
      };
    },
    computed: {
      hour: {
        get() {
          return this.internalTimeObject ? this.internalTimeObject.format(this.hourFormat) : 12;
        },
        set(v) {
          const value = parseInt(v, 10);
          const date = this.internalTimeObject ? this.internalTimeObject : this.defaultTime;
          this.internalTimeObject = moment(date).set('hour', value);
        },
      },
      minute: {
        get() {
          return this.internalTimeObject ? this.internalTimeObject.format('mm') : '00';
        },
        set(v) {
          const value = parseInt(v, 10);
          const date = this.internalTimeObject ? this.internalTimeObject : this.defaultTime;
          this.internalTimeObject = moment(date).set('minute', value);
        },
      },
      partOfDay: {
        get() {
          return this.internalTimeObject ? this.internalTimeObject.format('A') : 'AM';
        },
        set(v) {
          const value = parseInt(v, 10);
          const date = this.internalTimeObject ? this.internalTimeObject : this.defaultTime;
          this.internalTimeObject = moment(date).add(value, 'hours');
        },
      },
      timeFormat() {
        return this.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      },
      hourFormat() {
        return this.locale === 'en' ? 'hh' : 'HH';
      },
      placeholder() {
        return this.locale === 'en' ? 'hh:mm AM' : 'HH:MM';
      },
      clear() {
        return this.locale === 'en' ? 'Clear' : 'Törlés';
      },
      classes() {
        return {
          'timepicker-popperOpen': this.popperOpen,
          'timepicker-locale-hu': this.locale === 'hu',
          'timepicker-locale-en': this.locale === 'en',
          'timepicker-disabled': this.disabled,
          'timepicker-sm': this.size === 'small',
          'design-system': this.designSystem,
        };
      },
      formClasses() {
        return {
          'form-control-sm': this.size === 'small',
          'is-invalid': this.error,
        };
      },
    },
    mounted() {
      this.internalTimeObject = this.value !== null ? moment(this.value) : null;
    },
    methods: {
      sendInputTime() {
        const time = this.internalTimeObject ? this.internalTimeObject.toDate() : null;
        this.$emit('change', time);
      },
      modifyHours(value) {
        const v = parseInt(value, 10);
        this.hour = v;
      },
      modifyMinutes(value) {
        const v = parseInt(value, 10);
        this.minute = v;
      },
      setHours(value) {
        const v = parseInt(value, 10);
        this.hour = this.internalTimeObject.hour() + v;
        this.sendInputTime();
      },
      setMinutes(value) {
        const v = parseInt(value, 10);
        this.minute = parseInt(this.minute, 10) + v;
        this.sendInputTime();
      },
      setPartOfDay(value) {
        const v = parseInt(value, 10);
        this.partOfDay = v;
        this.sendInputTime();
      },
      clearTime() {
        this.internalTimeObject = null;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'timePicker.sass'
</style>
