<template lang="pug">
span
  a.body-text-link(v-if="href" :class="classes" :href="href" :target="target")
    slot
  router-link.body-text-link(v-else-if="to" :class="classes" :to="to" :target="target")
    slot
</template>

<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmBodyTextLink',
    mixins: [designSystemMixin],
    props: {
      btl400xs: {
        type: Boolean,
        default: false,
      },
      btl400sm: {
        type: Boolean,
        default: false,
      },
      btl400md: {
        type: Boolean,
        default: false,
      },
      btl400lg: {
        type: Boolean,
        default: false,
      },
      btl500sm: {
        type: Boolean,
        default: false,
      },
      btl500md: {
        type: Boolean,
        default: false,
      },
      btl500lg: {
        type: Boolean,
        default: false,
      },
      orange: {
        type: Boolean,
        default: false,
      },
      gray: {
        type: Boolean,
        default: false,
      },
      to: {
        type: [String, Object],
        default: () => '',
      },
      href: {
        type: String,
        default: '',
      },
      target: {
        type: String,
        default: '_self',
        options: ['_self', '_blank', '_parent', '_top'],
        validator: (value) => {
          return ['_self', '_blank', '_parent', '_top'].includes(value.toLowerCase());
        },
      },
    },
    computed: {
      classes() {
        return {
          'body-text-link-400-xs': this.btl400xs,
          'body-text-link-400-sm': this.btl400sm,
          'body-text-link-400-md': this.btl400md,
          'body-text-link-400-lg': this.btl400lg,
          'body-text-link-500-sm': this.btl500sm,
          'body-text-link-500-md': this.btl500md,
          'body-text-link-500-lg': this.btl500lg,
          'orange-500': this.orange,
          'gray-700': this.gray,
          'design-system': this.designSystem,
        };
      },
    },
    methods: {
      onClick() {
        if (this.to) {
          this.$router.push(this.to);
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'bodyTextLink.sass'
</style>
