import { render, staticRenderFns } from "./ImageUpload.vue?vue&type=template&id=4a1b30e7&lang=pug&"
import script from "./ImageUpload.vue?vue&type=script&lang=js&"
export * from "./ImageUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports