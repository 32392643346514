<template lang="pug">
.all-caps(:class="classes")
  slot
</template>
<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmAllCapsText',
    mixins: [designSystemMixin],
    props: {
      normal: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: false,
      },
      orange: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return {
          'all-caps-normal': this.normal,
          'all-caps-bold': this.bold,
          'orange-500': this.orange,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'allcapstext.sass'
</style>
