<template lang="pug">
.ds-patch(:class="`ds-patch-${index}`")
  inline-svg(:src="require(`./svgs/${index}.svg`)")
</template>

<script>
  import InlineSvg from 'vue-inline-svg';

  export default {
    name: 'OmPatches',
    components: { InlineSvg },
    props: {
      index: {
        type: Number,
        default: 1,
      },
    },
  };
</script>
