import { mapState, mapGetters } from 'vuex';
import { CURRENT_URL_2024, isFeatureEnabled } from '@/utils/features';
import { RULE_CURRENT_PAGE_URL, RULE_CURRENT_PAGE_URL_V2 } from '@/config/frontendRules/rules';
import rulesFilter from './targetingRulesFilter';

export default {
  props: {
    campaignType: {
      type: String,
      default: null,
    },
    personalizedRules: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState(['account']),
    ...mapGetters(['isShopifyDomain', 'isKlaviyoDomain', 'isActiveShoprenterDomain']),
    shopifyDomain() {
      return this.isShopifyDomain(this.domain);
    },
    klaviyoDomain() {
      return this.isKlaviyoDomain(this.domain);
    },
    shoprenterDomain() {
      return this.isActiveShoprenterDomain(this.domain);
    },
    isEmbedded() {
      return this.campaignType === 'embedded';
    },
    isDynamicContent() {
      return this.campaignType === 'dynamic_content';
    },
    features() {
      return this.account?.features ?? [];
    },
    currentUrlVersion() {
      return isFeatureEnabled(this.features, CURRENT_URL_2024)
        ? RULE_CURRENT_PAGE_URL_V2
        : RULE_CURRENT_PAGE_URL;
    },
    filterData() {
      return {
        isShopifyDomain: this.shopifyDomain,
        isKlaviyoDomain: this.klaviyoDomain,
        isShoprenterDomain: this.shoprenterDomain,
        isEmbedded: this.isEmbedded,
        campaignVersion: this.campaignVersion,
        isDynamicContent: this.isDynamicContent,
        personalizedRules: this.personalizedRules,
        currentUrlVersion: this.currentUrlVersion,
      };
    },
    filteredRules() {
      return rulesFilter(this.rules, this.filterData);
    },
  },
};
