<template lang="pug">
.sidebar-tab-content(v-if="sh")
  slot
</template>

<script>
  import { nanoid } from 'nanoid';

  const TYPE_SIDEBAR = 'sidebar';
  const ALLOWED_TYPES = [TYPE_SIDEBAR, 'colorpicker'];
  const isValidType = (type) => ALLOWED_TYPES.includes(type);

  export default {
    props: {
      id: { type: String, default: () => nanoid(9) },
      type: {
        type: String,
        default: () => TYPE_SIDEBAR,
        validator: isValidType,
      },
    },
    data: () => ({
      sh: false,
      accordions: {},
      firstAccordion: null,
    }),
    computed: {
      isSidebar() {
        return this.type === TYPE_SIDEBAR;
      },
    },
    watch: {
      sh: {
        immediate: true,
        handler() {
          this.firstAccordion = null;
          this.accordions = {};
        },
      },
    },
    created() {
      if (this.isSidebar) {
        this.$bus.$on('toggleAccordion', (id) => {
          this.toggleAccordion(id);
        });
        this.$bus.$on('registerAccordion', this.addAccordion);
      }
    },
    beforeDestroy() {
      if (this.isSidebar) {
        this.$bus.$off('toggleAccordion', this.toggleAccordion);
        this.$bus.$off('registerAccordion', this.addAccordion);
      }
    },
    mounted() {
      setTimeout(() => {
        this.closeAll();
        this.openFirst();
      }, 200);
    },
    methods: {
      getId() {
        return this.id;
      },
      show() {
        this.closeAll();
        this.sh = true;
        setTimeout(() => {
          this.openFirst();
        }, 150);
      },
      hide() {
        this.closeAll();
        this.sh = false;
      },
      toggleAccordion({ id, canRemainOpen }) {
        if (!this.accordions) return;

        const accordion = this.accordions[id];
        if (!accordion) return;
        // eslint-disable-next-line no-unused-expressions
        canRemainOpen ? '' : this.closeAll();
        accordion.open();
      },
      closeAll() {
        for (const id in this.accordions) {
          const a = this.accordions[id];
          if (a && !a.leftSidebar) a.close();
        }
      },
      addAccordion(accordion) {
        this.accordions[accordion._uid] = accordion;
        if (!this.firstAccordion) this.firstAccordion = accordion;
      },
      openFirst() {
        if (this.firstAccordion && this.firstAccordion.open && this.firstAccordion.active) {
          this.firstAccordion.open();
        } else {
          let alreadyOpen = false;
          Object.keys(this.accordions).forEach((id) => {
            const accordion = this.accordions[id];

            if (
              !alreadyOpen &&
              accordion &&
              accordion.isActive &&
              accordion.isActive() &&
              accordion.open
            ) {
              alreadyOpen = true;
              accordion.open();
            }
          });
        }
      },
    },
  };
</script>
