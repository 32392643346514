<template lang="pug">
.accordion-v2
  slot
</template>

<script>
  export default {
    name: 'Accordion',
    props: { multi: { type: Boolean, default: false } },
    data: () => ({
      current: null,
    }),
    methods: {
      toggle(child) {
        const isActive = !child.active;
        if (!this.multi) {
          if (this.current) this.current.active = false;
          if (isActive) this.current = child;
        }
        child.active = isActive;
      },
    },
  };
</script>

<style lang="sass">
  .accordion-v2
    &-item + .accordion-v2-item
      margin-top: .75rem
</style>
