<template>
  <svg
    class="img-fluid"
    width="480"
    height="265"
    viewBox="0 0 411 265"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H407C409.209 0 411 1.79086 411 4V262C411 263.657 409.657 265 408 265H2.99999C1.34314 265 0 263.657 0 262V4Z"
      fill="#D8DBDF"
    />
    <ellipse cx="11.3854" cy="9.27161" rx="4.11" ry="4.10853" fill="#AFB9BE" />
    <ellipse cx="25.11" cy="9.27161" rx="4.11" ry="4.10853" fill="#AFB9BE" />
    <ellipse cx="38.11" cy="9.27161" rx="4.11" ry="4.10853" fill="#AFB9BE" />
    <path
      d="M0.378906 19.6914H410.618V261.455C410.618 263.112 409.275 264.455 407.618 264.455H3.37892C1.72207 264.455 0.378906 263.112 0.378906 261.455V19.6914Z"
      fill="#E3E8ED"
    />
    <rect x="18.4785" y="37.6719" width="374.042" height="24.1231" fill="#D3DAE1" />
    <rect x="18.4766" y="67.8252" width="374.042" height="84.4308" fill="#D3DAE1" />
    <rect x="18.4766" y="157.712" width="120.659" height="50.4282" fill="#D3DAE1" />
    <rect x="18.4766" y="215.704" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="18.4766" y="223.269" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="18.4766" y="230.832" width="74.8084" height="3.78211" fill="#D3DAE1" />
    <rect x="145.169" y="157.712" width="120.659" height="50.4282" fill="#D3DAE1" />
    <rect x="145.169" y="215.704" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="145.169" y="223.269" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="145.169" y="230.832" width="74.8084" height="3.78211" fill="#D3DAE1" />
    <rect x="272.01" y="157.712" width="120.659" height="50.4282" fill="#D3DAE1" />
    <rect x="272.01" y="215.704" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="272.01" y="223.269" width="120.659" height="3.78211" fill="#D3DAE1" />
    <rect x="271.859" y="230.832" width="74.8084" height="3.78211" fill="#D3DAE1" />
    <rect x="18.6279" y="241.65" width="180.977" height="22.804" fill="#D3DAE1" />
    <rect x="211.392" y="241.65" width="180.977" height="22.804" fill="#D3DAE1" />
    <path
      opacity="0.45"
      d="M0 18.0273H411V261.539C411 263.196 409.657 264.539 408 264.539H2.99999C1.34314 264.539 0 263.196 0 261.539V18.0273Z"
      fill="#333333"
    />
    <g filter="url(#filter0_d_2576:138477)">
      <rect x="69.291" y="58.8281" width="273.367" height="166.338" rx="8" fill="white" />
    </g>
    <rect x="97.4092" y="119.016" width="185.414" height="10.9308" rx="5.46538" fill="#D8DDE1" />
    <rect x="95.8467" y="85.375" width="131.997" height="17.1769" rx="8.58846" fill="#D8DDE1" />
    <rect x="97.4092" y="138.025" width="130.741" height="10.9308" rx="5.46538" fill="#D8DDE1" />
    <rect x="188.792" y="173.602" width="83.1987" height="28.5151" rx="6" :fill="color" />
    <rect
      x="98.4092"
      y="174.669"
      width="81.1987"
      height="26.5151"
      rx="5"
      fill="white"
      :stroke="color"
      stroke-width="2"
    />
    <path
      d="M342.658 192.758C342.658 108.82 280.695 68.4974 249.713 58.8281H334.658C339.076 58.8281 342.658 62.4037 342.658 66.822V192.758Z"
      fill="url(#paint0_linear_2576:138477)"
    />
    <path
      d="M332.511 67.417C327.202 67.417 320.79 71.2278 320.79 75.9601C320.79 75.9878 320.797 76.0667 320.801 76.0945C320.81 76.3631 320.995 76.5941 321.255 76.6601C321.514 76.7266 321.789 76.613 321.925 76.3808C323.512 73.6741 325.84 71.5925 329.043 70.0164C329.34 69.868 329.706 69.9999 329.85 70.291C329.922 70.436 329.932 70.5997 329.88 70.7523C329.828 70.9049 329.721 71.028 329.576 71.0987C325.976 72.8709 323.558 75.3099 322.178 78.5498C322.156 78.5828 322.128 78.6103 322.112 78.6485C321.246 80.7342 320.801 83.2093 320.789 86.0056C320.577 85.4448 320.325 84.9115 320.055 84.3913C320.534 83.6557 320.789 82.8552 320.789 81.9933C320.789 77.261 314.378 73.4502 309.068 73.4502C308.21 73.4502 307.512 74.1484 307.512 75.0062C307.512 80.3135 311.324 86.7232 316.058 86.7232C317.223 86.7232 318.279 86.2676 319.206 85.3952C320.264 87.6245 320.789 90.2424 320.789 93.3598C320.789 93.6933 321.059 93.9631 321.393 93.9631C321.727 93.9631 321.997 93.6933 321.997 93.3598V86.12C321.997 83.7587 322.336 81.668 322.968 79.8556C323.747 80.4063 324.601 80.6901 325.521 80.6901C330.255 80.6901 334.067 74.2804 334.067 68.9731C334.067 68.1152 333.369 67.417 332.511 67.417Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_2576:138477"
        x="57.291"
        y="52.8281"
        width="297.367"
        height="190.338"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2576:138477" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2576:138477"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2576:138477"
        x1="295.664"
        y1="218.886"
        x2="295.664"
        y2="58.8281"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        default: '',
      },
    },
    computed: {
      gradientStart() {
        return `lighten(${this.color}, 0.1)`;
      },
      gradientEnd() {
        return `darken(${this.color}, 0.1)`;
      },
    },
  };
</script>
