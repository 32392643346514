<template lang="pug">
inline-svg.brand-monks(:class="classes" :src="require(`./svgs/${monk}.svg`)")
</template>

<script>
  export default {
    props: {
      monk: {
        type: String,
      },
      fluid: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      classes() {
        return {
          [`brand-monk-${this.monk}`]: true,
          'brand-monks-fluid': this.fluid,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-monks
    &-fluid
      width: 100%
</style>
