<template lang="pug">
.user-segment-campaign(:class="{ 'justify-content-center': !campaignsLength }")
  loading-logo.mt-5.pt-5(v-if="loading")
  transition-group(name="fade")
    EmptyContent(
      key="empty-content"
      v-if="!loading && !campaignsLength"
      :text="$t(`userSegment.modals.choose.tabs.copy.empty`)"
    )
    .col-12.px-0(key="search-input" v-if="!loading && campaignsLength")
      om-input#user-segment-campaign-search-input(
        v-model="search"
        :placeholder="$t('userSegment.modals.choose.tabs.copy.search')"
      )
    .col-12.px-0.mt-4.user-segment-campaign-list(
      key="campaign-list"
      v-if="!loading && campaignsLength"
    )
      .user-segment-campaign-list-item.cursor-pointer.d-flex.justify-content-between.align-items-center.mb-2(
        v-for="campaign in campaigns"
        @click="select(campaign)"
        :class="{ active: selected === campaign._id }"
      )
        .user-segment-campaign-list-item-name.p-2 {{ campaign.name }}
        .user-segment-campaign-list-item-domain.mr-4.text-center.font-size-0--875 {{ campaign.domain }}
</template>
<script>
  import EmptyContent from '@/components/Segments/EmptyContent.vue';
  import CAMPAIGNS_FOR_SEGMENT_MODAL from '@/graphql/CampaignsForSegmentModal.gql';

  export default {
    components: { EmptyContent },
    props: {
      selected: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      loading: true,
      campaignsLength: null,
      campaigns: [],
      search: null,
    }),
    watch: {
      search() {
        this.select(null);
      },
    },
    apollo: {
      campaigns: {
        debounce: 300,
        query: CAMPAIGNS_FOR_SEGMENT_MODAL,
        variables() {
          return {
            search: this.search,
            id: parseInt(this.$route.params.id, 10),
          };
        },
        result({ data: { campaigns } }) {
          if (!this.campaignsLength) {
            this.campaignsLength = campaigns.length;
            this.$emit('isEmptyContent', !campaigns.length);
          }
          this.loading = false;
        },
      },
    },
    methods: {
      select(campaign) {
        this.$emit('select', campaign);
      },
    },
  };
</script>

<style lang="sass">
  @import '../../sass/variables/_colors.sass'
  .user-segment-campaign
    &-list
      height: 18rem
      overflow-x: hidden
      overflow-y: scroll
      &-item
        border-radius: 8px
        border: 2px solid transparent
        &.active
          border: 2px solid $om-orange-500
        &:not(.active):hover
          background: $om-gray-100
        &-name
          color: $om-gray-700
        &-domain
          color: $om-gray-600
</style>
