<template lang="pug">
div
  .img-preview.img-preview-logo.mb-4(v-if="imageSrc || previewImageSrc")
    img.cursor-pointer(
      ref="image"
      :src="imageSrc || previewImageSrc"
      @click.prevent="$refs.imageInput.click()"
    )
  input(
    ref="imageInput"
    type="file"
    style="display: none"
    :accept="acceptedMime"
    @change="handleFileSelect"
  )
  .row.no-gutters
    om-button(secondary @click.prevent="$refs.imageInput.click()") {{ $t('browse') }}
    om-button.ml-3(ghost v-if="imageFile || imageSrc" @click.prevent="resetImage()") {{ $t('reset') }}
</template>

<script>
  export default {
    model: {
      prop: 'imageFile',
      event: 'changeImageFile',
    },

    props: {
      acceptedMime: {
        type: String,
        default: 'image/jpeg|image/png|image/gif|text/svg',
      },
      imageFile: {
        type: File,
        default: null,
      },
      imageSrc: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        imageInput: null,
        previewImageSrc: null,
      };
    },

    methods: {
      handleFileSelect() {
        const input = this.$refs.imageInput;
        if (input.files.length > 0) {
          this.$emit('changeImageFile', input.files[0]);

          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImageSrc = e.target.result;
          };
          reader.readAsDataURL(input.files[0]);

          input.value = null;
        }
      },

      resetImage() {
        this.$emit('changeImageFile', null);
        this.$emit('update:imageSrc', null);
        this.previewImageSrc = null;

        this.$emit('reset');
      },
    },
  };
</script>
