<template lang="pug">
.brand-list-option(:class="classes")
  slot
  inline-svg.brand-list-option-check(:src="require('@/assets/admin/svg/icons/check-circle.svg')")
</template>

<script>
  export default {
    props: {
      selected: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classes() {
        return {
          'brand-list-option-selected': this.selected,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-list-option
    display: flex
    align-items: center
    height: 52px
    padding: 12px
    color: #23262A
    font-size: 18px
    line-height: 28px
    font-weight: 500
    background: white
    cursor: pointer
    transition: .3s

    &:hover
      background: #F7F7F8

    &-check
      display: none
      margin-left: auto

    &-selected
      color: #ED5A29
      background: #FFEFE5

      &:hover
        background: #FFEFE5

      .brand-list-option-check
        display: block
</style>
