var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('div',[_c('upload-area',{staticClass:"vc-upload-area",attrs:{"src":_vm.backgroundSrc,"editMobile":_vm.editMobile,"inColorPicker":true},on:{"click":_vm.imageManager,"remove":_vm.resetMobileImage}})],1),_c('div',{staticClass:"vc-sketch-top"},[_c('div',{staticClass:"vc-sketch-section mt-2"},[_c('div',{staticClass:"vc-sketch-section--title"},[_vm._v(_vm._s(_vm.$t('image')))]),_c('div',{staticClass:"mt-2"},[_c('image-position-select',{attrs:{"label":"position","property":_vm.imagePositionPath,"options":_vm.imagePositions,"typeOfComponent":_vm.typeOfComponent}}),_c('image-position-select',{staticClass:"mt-3",attrs:{"label":"repeat","property":_vm.imageRepeatPath,"options":_vm.imageRepeat,"typeOfComponent":_vm.typeOfComponent}})],1)]),(['contain', 'cover'].includes(_vm.selectedImagePosition))?_c('div',{staticClass:"vc-sketch-section mt-3"},[_c('div',{staticClass:"vc-sketch-section--title"},[_vm._v(_vm._s(_vm.$t('alignment')))]),_c('div',{staticClass:"d-flex"},[_c('align-select',{staticClass:"mt-2 mr-5 aligning",attrs:{"label":"horizontal","property":_vm.horizontalAlignPath,"options":_vm.imageHorizontalAlign.map(function (ref) {
	var key = ref.key;

	return key;
}),"vertical":true,"inline":false,"small":true,"showOption":true,"editMobile":true}}),_c('align-select',{staticClass:"mt-2 aligning",attrs:{"label":"vertical","property":_vm.selectedImagePosition === 'contain' ? _vm.imageAlignPath : _vm.imageVerticalAlign,"options":_vm.imageAlign.map(function (ref) {
	var key = ref.key;

	return key;
}),"inline":false,"small":true,"showOption":true,"editMobile":true}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }