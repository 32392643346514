<template lang="pug">
.rules-summary(:class="{ 'without-structure': withoutStructure }")
  template(v-for="(frontendRule, frontendRuleIndex) in frontendRulesToShow")
    .d-flex.align-items-center.rules-summary-row(:class="getRowLineType(frontendRuleIndex)")
      frontend-rule-box(:frontendRule="frontendRule" :campaign="{ domain }" small)
      .rule-logical-condition.rule-logical-condition-and(
        v-if="frontendRuleIndex < frontendRulesToShow.length - 1 && !withoutStructure"
        :class="`rule-logical-condition-and-${$i18n.locale}`"
      ) {{ $t('and') }}
</template>

<script>
  import { mapState } from 'vuex';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';
  import {
    CURRENT_URL_2024,
    isFeatureEnabled,
    VISITOR_CART_V3,
    VISITOR_CART_V3_UI,
  } from '@/utils/features';
  import {
    RULE_CART_RULES,
    RULE_CART_RULES_V2,
    RULE_CART_RULES_V3,
    RULE_CURRENT_PAGE_URL,
    RULE_CURRENT_PAGE_URL_V2,
  } from '@/config/frontendRules/rules';

  export default {
    components: { FrontendRuleBox },
    props: {
      domain: {
        type: String,
        default: 'optimonk.com',
      },
      frontendRules: {
        type: Array,
        default: () => [],
      },
      withoutStructure: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['account']),
      frontendRulesToShow() {
        const rules =
          this.frontendRules?.map((rule, index) => {
            return { ...rule, originalIndex: index };
          }) ?? null;
        return rules?.filter(({ type }) => !this.isHiddenRuleType(type)) ?? [];
      },
      features() {
        return this.account?.features ?? [];
      },
    },
    methods: {
      getRowLineType(index) {
        const rulesLength = this.frontendRulesToShow.length;
        const start = index === 0 && rulesLength > 1;
        const end = (index === rulesLength - 1 && rulesLength > 1) || rulesLength === 1;
        return {
          'rules-summary-row-line': start || end,
          'rules-summary-row-line-start': start,
          'rules-summary-row-line-end': end,
        };
      },
      isHiddenRuleType(type) {
        if (
          type === RULE_CURRENT_PAGE_URL &&
          isFeatureEnabled(this.features, CURRENT_URL_2024) === true
        )
          return true;
        if (
          type === RULE_CURRENT_PAGE_URL_V2 &&
          isFeatureEnabled(this.features, CURRENT_URL_2024) === false
        )
          return true;

        if (
          type === RULE_CART_RULES &&
          isFeatureEnabled(this.features, VISITOR_CART_V3) === true &&
          isFeatureEnabled(this.features, VISITOR_CART_V3_UI) === false
        )
          return true;
        if (
          type === RULE_CART_RULES_V2 &&
          isFeatureEnabled(this.features, VISITOR_CART_V3) === true &&
          isFeatureEnabled(this.features, VISITOR_CART_V3_UI) === false
        )
          return true;

        if (
          type === RULE_CART_RULES_V3 &&
          isFeatureEnabled(this.features, VISITOR_CART_V3) === false &&
          isFeatureEnabled(this.features, VISITOR_CART_V3_UI) === false
        )
          return true;

        return false;
      },
    },
  };
</script>

<style lang="sass">
  .rules-summary
    padding: 0 2.5rem 2rem !important
    font-size: 0.875rem
    position: relative

    &-title
      font-size: .9375rem
      font-weight: 500
      text-transform: uppercase
      color: #17181A
      margin-bottom: 1.25rem

  .rules-summary-row
    position: relative
    margin-bottom: .5625rem
    &:last-child
      margin-bottom: 0

    &:before,
    &:after
      position: absolute
      content: ''
      width: 2px
      height: 50%
      background: #D8DDE1
      top: 0
      left: -1.5rem
      max-height: 50%
      cursor: default !important
      pointer-events: none !important
    &:after
      top: 50%

    &-hide-line
      &:before,
      &:after
        content: none

    .rule-logical-condition
      bottom: -.5rem

      &-or
        &-hu
          left: -2.6875rem
        &-en
          left: -2.25rem
      &-and
        &-hu
          left: -2.187rem
        &-en
          left: -2.5rem

    &-line
      &.rules-summary-row-line-end
        &:before
          content: ''
          top: 0
        &:after
          content: none
      .brand-rule-box
        &:before
          position: absolute
          content: ''
          width: 0.9375rem
          height: 2px
          background: #D8DDE1
          top: 50%
          left: -1.5rem
          cursor: default !important
          pointer-events: none !important
      &.rules-summary-row-line-start
        .brand-rule-box
          &:before
            content: none
  .rules-summary.without-structure
    padding-left: 0 !important
    .brand-rule-box
      margin-left: 0 !important
      &:before
        display: none
    .rules-summary-row
      &:before,
      &:after
        display: none
</style>
