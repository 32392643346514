<template lang="pug">
div(:class="['vc-sketch', disableAlpha ? 'vc-sketch__disable-alpha' : '']")
  tab-wrapper.vc-tab-wrapper(
    ref="tabWrapper"
    :class="{ 'vc-tab-wrapper-empty': !showTabs }"
    customClass="vc-tab-tabs"
    v-if="!palette"
    @show-content="setBackgroundType"
  )
    template(v-if="showTabs" #tabs)
      tab-item#transparent(:name="$t('colorPickerSettings.tabs.name.none')")
      tab-item#solid-fill(:name="$t('colorPickerSettings.tabs.name.solid')")
      tab-item#gradient(:name="$t('colorPickerSettings.tabs.name.gradient')")
      tab-item#image(
        :name="$t('colorPickerSettings.tabs.name.image')"
        v-if="(!selectedElement || selectedElement.type !== 'OmDropdown') && !activeProperty.includes('hover')"
      )
    template(v-else #tabs)
      span
    template(#content)
      tab-content#transparent
        .vc-tab-content
          .vc-sketch-saturation-wrap.transparent-bg
      tab-content#solid-fill
        theme-colors(@handlePreset="handlePreset" @handleRecent="handleRecent")
        .vc-tab-content
          .vc-sketch-saturation-wrap
            saturation(v-model="colors" @change="childChange" :hue="currentHue")

          template(v-if="!palette")
            .vc-tab-content
              solid(
                :colors="colors"
                :disableAlpha="disableAlpha"
                @inputChange="inputChange"
                @childChange="childChange"
                @handlePreset="handlePreset"
                @handleRecent="handleRecent"
              )

      tab-content#gradient
        .vc-sketch-top
          gradient(
            @inputChange="inputChange"
            @setActiveProp="setActiveProp"
            :directionProperty="getPath(`${property}.linearDirection`)"
            :defaultValue="1"
            :property="activeProperty"
            :min="0"
            :max="360"
            :step="1"
          )
        theme-colors(@handlePreset="handlePreset" @handleRecent="handleRecent")
        .vc-tab-content
          .vc-sketch-saturation-wrap
            saturation(v-model="colors" @change="childChange" :hue="currentHue")

          solid(
            :colors="colors"
            :disableAlpha="disableAlpha"
            @inputChange="inputChange"
            @childChange="childChange"
            @handlePreset="handlePreset"
            @handleRecent="handleRecent"
          )

      tab-content#image
        .vc-tab-content(v-if="activeProperty.startsWith('globalStyle')")
          image-picker(
            :propsProperty="activeProperty"
            :property="activeProperty"
            :isGlobalSubPath="isGlobalSubPath"
            :typeOfComponent="typeOfComponent"
          )
        .vc-tab-content(v-else)
          image-picker(
            propsProperty="selectedElement"
            :property="activeProperty"
            :isGlobalSubPath="isGlobalSubPath"
            :typeOfComponent="typeOfComponent"
          )

  .vc-sketch-saturation-wrap(v-if="palette")
    saturation(v-model="colors" @change="childChange" :hue="currentHue")

  template(v-if="palette")
    .vc-sketch-bottom
      .vc-sketch-sliders
        .vc-sketch-hue-wrap
          hue(v-model="colors" @change="childChange")
      .vc-sketch-field
        .vc-sketch-field--double.mt-2
          ed-in(label="hex" :value="hex" @change="inputChange")

  .color-reset-box(v-if="isQuillPicker")
    a.color-reset(href="#" @click.prevent="resetColor") {{ $t('reset') }}
</template>

<script>
  import TabWrapper from '@/editor/components/sidebar/tab/Wrapper.vue';
  import TabItem from '@/editor/components/sidebar/tab/Item.vue';
  import TabContent from '@/editor/components/sidebar/tab/Content.vue';
  import { mapState } from 'vuex';
  import { lsRetrieve, lsStore } from '@/helpers/localStorage';
  import tinycolor from 'tinycolor2';
  import { get as _get } from 'lodash-es';
  import { colorChangeTracker } from '@/services/userInteractionTracker/tracker';
  import itemMixin from '@/editor/mixins/item';
  import { MAX_ACCEPTED_RECENTLY_COLORS } from '@/config/constants';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import colorMixin from '../mixin/color';
  import editableInput from './common/EditableInputOm.vue';
  import saturation from './common/Saturation.vue';
  import hue from './common/Hue.vue';
  import alpha from './common/Alpha.vue';
  import ThemeColors from './common/ThemeColors.vue';
  import Checkboard from './common/Checkboard.vue';
  import Solid from './tabs/Solid.vue';
  import Gradient from './tabs/Gradient.vue';
  import ImagePicker from './tabs/Image.vue';

  const globalSubpaths = [
    'selectedPage',
    'globalStyle.overlay',
    'globalStyle.tab',
    'selectedElement.subElements.button.desktop',
  ];

  const presetColors = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
  ];

  export default {
    name: 'Sketch',
    components: {
      saturation,
      hue,
      alpha,
      ThemeColors,
      'ed-in': editableInput,
      Checkboard,
      TabWrapper,
      Solid,
      Gradient,
      ImagePicker,
      TabItem,
      TabContent,
    },
    mixins: [itemMixin, colorMixin, themeColorsMixin],
    props: {
      presetColors: {
        type: Array,
        default() {
          return presetColors;
        },
      },
      disableAlpha: {
        type: Boolean,
        default: false,
      },
      disableFields: {
        type: Boolean,
        default: false,
      },
      typeOfComponent: {
        type: String,
        default: 'global',
      },
      inEditor: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      currentHue: 0,
      allColors: null,
      activeProperty: '',
    }),
    computed: {
      ...mapState([
        'isEditor',
        'mobilePreview',
        'globalStyle',
        'images',
        'colorPicker',
        'selectedElement',
      ]),
      hex() {
        return this.colors.hex;
      },
      recentlyUsed() {
        return this.getRecentlyUsedColors();
      },
      activeColor() {
        const rgba = this.colors.rgba;
        return tinycolor(rgba).toRgbString();
      },
      isQuillPicker() {
        const property = _get(this.$store.state, 'colorPicker.property');
        return property && property.startsWith('omcolor');
      },
      palette() {
        return _get(this.$store.state, 'colorPicker.palette');
      },
      templatePalette() {
        return _get(window, 'om.template.palette');
      },
      backgroundType() {
        if (this.isGlobalSubPath) {
          const path = this.getPath(`${this.property}.type`);
          const type = this.getValueOf(path);

          return type;
        }

        let type = this.activeProperty.includes('globalStyle')
          ? this.getValueOf(`${this.activeProperty}.type`)
          : this.smartGetValueOf(`desktop.${this.property}.type`);
        const mobileType = this.activeProperty.includes('globalStyle')
          ? this.getValueOf(`${this.activeProperty}.type`)
          : this.smartGetValueOf(`mobile.${this.property}.type`);

        if (this.mobilePreview && mobileType) {
          type = mobileType;
        }

        return type;
      },
      isGlobalSubPath() {
        return globalSubpaths.includes(this.subPath);
      },
      property() {
        const isBGProp = this.colorPicker.property.includes('background');
        return isBGProp ? 'background' : 'hover';
      },
      propertyPath() {
        const { activeProperty } = this;
        const property = activeProperty.includes('globalStyle')
          ? `${activeProperty}.type`
          : this.getPath(`${this.property}.type`);
        return property;
      },
      showTabs() {
        return (
          (this.activeProperty.includes('background') || this.activeProperty.includes('hover')) &&
          !this.palette &&
          !this.activeProperty.includes('close') &&
          !this.activeProperty.includes('subElements') &&
          !this.activeProperty.includes('backgroundFillColor')
        );
      },
      modifierFreePropertyPath() {
        return this.colorPicker.property.replace(
          /(selectedPage|selectedRow|selectedColumn|selectedElement)\./,
          '',
        );
      },
      isBrandColorPicked() {
        return this.colorPicker.index !== null && typeof this.colorPicker.index !== 'undefined';
      },
    },
    watch: {
      hex(v) {
        this.$emit('change', { hex: v });
      },
    },
    created() {
      if (this.colorPicker.property.includes('background.color')) {
        this.prepareColorPickerForDefaultBgColor();
      }
    },
    mounted() {
      this.currentHue = this.colors.hsl.h;
      this.allColors = this.colors;
      if (this.inEditor) {
        this.setActiveProp();
        this.$nextTick(() => {
          this.setActiveTab();
        });
      }
    },
    beforeDestroy() {
      this.addRecentlyUsedColor(this.activeColor);
    },
    methods: {
      trackColorChange(type, value = null) {
        colorChangeTracker.track(this.colorPicker.property, {
          type,
          value,
        });
      },
      prepareColorPickerForDefaultBgColor() {
        const isDefaultDesktopBg =
          this.colorPicker.property === 'desktop.background.color' &&
          this.selectedElement.desktop.background &&
          this.selectedElement.desktop.background.color === 0 &&
          ['transparent', null].includes(this.selectedElement.desktop.background.type);
        const isDefaultMobileBg =
          this.colorPicker.property === 'mobile.background.color' &&
          this.selectedElement.mobile.background &&
          this.selectedElement.mobile.background.color === 0 &&
          ['transparent', null].includes(this.selectedElement.mobile.background.type);

        if (isDefaultDesktopBg || isDefaultMobileBg) {
          this.handlePreset(this.getDefaultColor());
        }
      },
      resetColor() {
        const firstTemplateColor = this.templatePalette[0] ?? '#FFFFFF';
        const colorHex = firstTemplateColor.match(/^rgb\(.*\)$/)
          ? tinycolor(firstTemplateColor).toHexString()
          : firstTemplateColor;
        this.handlePreset({ colorHex, index: 0 });
      },
      getRecentlyUsedColors() {
        const recentlyUsedColors = _get(this.$store.state, 'colorPicker.recentlyUsedColor') || [];

        if (this.disableAlpha) {
          const filteredColors = [];
          recentlyUsedColors.forEach((color) => {
            const colorObj = tinycolor(color);
            if (colorObj._a === 1) {
              filteredColors.push(color);
            }
          });
          return filteredColors;
        }

        return recentlyUsedColors;
      },
      addRecentlyUsedColor(newColor) {
        const recentlyUsedColors = this.getRecentlyUsedColors();

        if (!recentlyUsedColors) {
          this.$store.commit('setRecentlyUsedColor', [newColor]);
          return;
        }
        const recentlyUsedColorsWithoutNewColor = recentlyUsedColors.filter(
          (color) => color !== newColor,
        );
        recentlyUsedColorsWithoutNewColor.unshift(newColor);
        this.$store.commit(
          'setRecentlyUsedColor',
          recentlyUsedColorsWithoutNewColor.splice(0, MAX_ACCEPTED_RECENTLY_COLORS),
        );
      },
      generateHexOrRGBA(color, source = 'hex') {
        let data = { [source]: color };
        if (/^rgb/.test(color)) {
          const rgba = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.exec(color);
          const keys = ['r', 'g', 'b', 'a'];
          data = {};
          rgba.forEach((found, i) => {
            if (!i) return;
            data[keys[i - 1]] = i < 4 ? parseInt(found, 10) : parseFloat(found);
          });
        }
        return data;
      },
      handleRecent({ colorHex }) {
        this.trackColorChange('recent');

        const source = 'hex';
        let data = this.generateHexOrRGBA(colorHex);

        this.colorChange({ ...data, source });
        data = tinycolor(data).toRgbString();

        this.temporarySaveRecent(data);
        this.$store.commit('proposeColor', data);
      },
      handlePreset({ colorHex, index }) {
        this.trackColorChange('template', index);

        const data = tinycolor(colorHex).toRgbString();
        this.temporarySaveRecent(data);
        this.$store.commit('usePresetColor', {
          index,
          value: data,
        });
        const colorChangeData = this.generateHexOrRGBA(colorHex);
        const source = 'hepax';
        this.colorChange({ ...colorChangeData, source });
      },

      isWhite(data) {
        return data.source === 'hsl' && data.s === 0 && data.l > 0.5;
      },

      isBlack(data) {
        return data.source === 'hsl' && data.s === 0 && data.l < 0.5;
      },

      childChange(data) {
        this.trackColorChange('custom');

        if (data.source && ['hsl', 'hsv', 'hsla', 'hsva'].includes(data.source))
          this.currentHue = data.h || this.colors.hsl.h || 0;

        if (this.isWhite(data)) data = { h: data.h, s: 0.1, l: 0.9, source: 'hsl', a: 1 };

        if (this.isBlack(data)) data = { h: data.h, s: 0.1, l: 0.1, source: 'hsl', a: 1 };

        const hex =
          data.length === 3
            ? tinycolor(data).toString('hex3').replace('#', '').toUpperCase()
            : tinycolor(data).toHexString().toUpperCase();
        const rgba = tinycolor(data).toRgbString();

        if (this.isEditor) {
          if (this.palette && !this.isBrandColorPicked) {
            this.$store.commit('updateMainColor', rgba);
            this.$bus.$emit('historySave');
            this.$bus.$emit('rebuild-color-instance');
          } else if (this.palette && this.isBrandColorPicked) {
            this.handleThemeColorClick(rgba);
            this.$bus.$emit('rebuild-color-instance');
            return;
          } else {
            this.$store.commit('proposeColor', rgba);
          }
        }

        this.temporarySaveRecent(data.source ? rgba : hex);
        this.colorChange(data.source ? rgba : hex);
      },
      handleThemeColorClick(rgba) {
        const fallbackColor =
          this.globalStyle.palette.themeColors[this.colorPicker.index]?.themeColor;

        let color = tinycolor(rgba).toHexString();
        const name = this.globalStyle.palette.themeColors[this.colorPicker.index]?.name;

        if (color === '#000000') color = fallbackColor;

        this.$store.commit('updateThemeColor', {
          themeColor: color,
          name,
          index: this.colorPicker.index,
        });

        this.colorChange(color);
        this.$bus.$emit('historySave');
      },
      temporarySaveRecent(data) {
        if (data.hex) {
          data = tinycolor(data).toRgbString();
        }
        const stored = lsRetrieve('tempRecentColors') || [];
        stored.push(data);
        lsStore('tempRecentColors', stored);
      },
      inputChange(data) {
        if (!data) {
          return;
        }
        if (data.hex && this.isValidHex(data.hex)) {
          this.childChange(data.hex);
        } else if (data.r || data.g || data.b || data.a) {
          const rgba = {
            r: data.r || this.colors.rgba.r,
            g: data.g || this.colors.rgba.g,
            b: data.b || this.colors.rgba.b,
            a: data.a || this.colors.rgba.a,
            source: 'rgba',
          };
          this.childChange(rgba);
        }
      },

      getPath(path) {
        let result = `${this.editedDeviceType}.${path}`;

        if (this.subPath && this.subPath === 'selectedPage') {
          result = `${this.subPath}.desktop.${path}`;
        } else if (this.subPath) {
          result = `${this.subPath}.${path}`;
        }

        return result;
      },
      setActiveProp(val) {
        let selectedProperty = null;
        if (!val && this.colorPicker.property) {
          if (
            this.colorPicker.property.endsWith('mainColor') ||
            this.colorPicker.property.endsWith('themeColors')
          ) {
            selectedProperty = this.colorPicker.property;
          } else if (
            (this.colorPicker.property.includes('hover') ||
              this.colorPicker.property.includes('background')) &&
            !this.colorPicker.property.includes('subElements') &&
            !this.colorPicker.property.includes('omcolor') &&
            !this.colorPicker.property.includes('luckyWheel') &&
            !this.colorPicker.property.includes('backgroundColor') &&
            !this.colorPicker.property.includes('checkedColor')
          ) {
            this.activeProperty = `${this.colorPicker.property.split('.').slice(0, -1).join('.')}`;
            selectedProperty = `${this.activeProperty}.color`;
          } else {
            this.activeProperty = this.colorPicker.property;
            selectedProperty = this.activeProperty;
          }
        } else {
          selectedProperty = val;
        }
        this.$store.commit('setColorPickerProperty', selectedProperty);

        if (this.backgroundType === 'gradient' || (this.palette && !this.isBrandColorPicked)) {
          const colorValue = this.getValueOf(selectedProperty);
          this.colorChange(tinycolor(colorValue).toHex());
          this.$store.commit('proposeColor', colorValue);
        }

        if (this.isBrandColorPicked) {
          const colorValue = this.getValueOf(`${selectedProperty}[${[this.colorPicker.index]}]`);
          this.colorChange(colorValue.themeColor);
        }
      },
      setBackgroundType(value) {
        const isBGProp = this.colorPicker.property.includes('background');
        const isHoverProp = this.colorPicker.property.includes('hover');

        if (value === 'solid-fill') {
          this.setActiveProp(`${this.editedDeviceType}.${this.property}.color`);
        }
        if (isBGProp || isHoverProp) {
          const property = this.propertyPath;
          const setter = property.includes('globalStyle') ? 'setValueOf' : 'smartSetValueOf';

          if (this.mobilePreview && value !== 'image' && this.backgroundType === value) {
            this[setter](property, null);
          } else {
            this[setter](property, value);
          }

          this.setActiveTab(value);
          window.om.bus.$emit('userInputChange', { property, value });
        } else {
          this.setActiveTab('solid-fill');
        }
      },
      resetMobileImage() {
        this.smartSetValueOf('mobile.background.imageId', null, true);
      },

      setSolidWhenTransparentIsDefaultAndOnlySolidTrue(type) {
        const backgroundType = this.colorPicker.property.replace('color', 'type');
        const storedType = this.smartGetValueOf(backgroundType);

        if (storedType === 'transparent' && this.colorPicker.onlySolid) {
          this.smartSetValueOf(backgroundType, type);
        }
      },
      setActiveTab(tab = null) {
        let type = this.colorPicker.onlySolid ? 'solid-fill' : tab;
        if (this.colorPicker.property.includes('subElements')) {
          this.setSolidWhenTransparentIsDefaultAndOnlySolidTrue(type);
        }

        if (!type) {
          const isBGProp =
            (this.colorPicker.property.includes('background') ||
              this.colorPicker.property.includes('hover')) &&
            !this.colorPicker.property.includes('backgroundColor'); // fixes product component button hover background color

          type = isBGProp ? this.backgroundType : 'solid-fill';
        }

        const wrapper = this.$refs.tabWrapper;

        if (!wrapper) return;

        wrapper.$children.forEach((child) => {
          if (child.deactivate) child.deactivate();
          if (child.hide) child.hide();
          if (child && child.id && child.id === type) {
            this.$nextTick(() => {
              if (child.activate) child.activate();
              if (child.show) child.show();
            });
          }
        });
      },
      getDefaultColor() {
        const index = 0;
        const firstTemplateColor = this.templatePalette[index] ?? '#FFFFFF';
        const colorHex = firstTemplateColor.match(/^rgb\(.*\)$/)
          ? tinycolor(firstTemplateColor).toHexString()
          : firstTemplateColor;
        return { colorHex, index };
      },
    },
  };
</script>

<style lang="sass">
  .vc-tab-wrapper-empty
    .sidebar-tab-wrapper-tabs
      margin: 0 !important
  .vc-sketch
    .sidebar-tab-wrapper-tabs
      border-bottom: 1px solid #E3E5E8
</style>
