<template lang="pug">
.sidebar-tab-wrapper
  .sidebar-tab-wrapper-tabs.d-flex-1.justify-content-around(v-if="getTabs")
    slot(name="tabs")
      sidebar-tab-item#general(:name="$t('sidebarSettings.tabs.name.general')" @clicked="clicked")
      sidebar-tab-item#style(
        :name="$t('sidebarSettings.tabs.name.style')"
        v-if="showStyleTab"
        @clicked="clicked"
      )
      sidebar-tab-item#advanced(
        :name="$t('sidebarSettings.tabs.name.advanced')"
        @clicked="clicked"
      )
  .sidebar-tab-wrapper-content
    transition(name="fade" mode="out-in")
      .editor-right-pane
        slot(name="content")
  .sidebar-tab-wrapper-bottom
    slot(name="bottom")
</template>

<script>
  import { mapState } from 'vuex';
  import { track } from '@/services/xray';

  export default {
    props: {
      defaultTab: { type: String, default: null },
      getTabs: { type: Boolean, default: true },
      showStyleTab: { type: Boolean, default: true },
    },
    computed: {
      ...mapState(['selectedElement']),
    },
    created() {
      window.om.bus.$on('show-sidebar-content', this.showContent);
      this.$on('show-content', this.showContent);

      if (this.defaultTab) {
        setTimeout(() => {
          this.$children.forEach((child) => {
            if (child.getId() === this.defaultTab) {
              if (child.show) child.show();
              else if (child.activate) child.activate();
            }
          });
        }, 150);
      }
    },
    mounted() {
      this.$emit('change');
    },
    methods: {
      clicked({ name }) {
        this.$emit('clicked', { name });
      },
      isContent(child) {
        return child.$options._componentTag !== 'sidebar-tab-item';
      },
      showContent(id) {
        let toShow;
        let toActivate;

        this.$emit('change');
        this.$children.forEach((child) => {
          const sameId = child.getId() === id;

          if (this.isContent(child)) {
            if (child.hide) child.hide();
            if (sameId) toShow = child;
          } else {
            if (child.deactivate) child.deactivate();
            if (sameId) toActivate = child;
          }
        });
        if (toShow && toShow.show) toShow.show();
        if (toActivate && toActivate.activate) toActivate.activate();

        track('editor-tab-change', {
          tab: id,
          componentType: this.selectedElement.type,
        });
      },
    },
  };
</script>
