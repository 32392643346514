<template lang="pug">
.brand-tag-el(:style="styles" :class="classes")
  router-link(v-if="link" :to="link")
    slot
  slot(v-else)
</template>

<script>
  export default {
    props: {
      background: {
        type: String,
        default: 'white',
      },
      color: {
        type: String,
        default: '#979797',
      },
      boxShadow: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      link: {
        type: [String, Object],
        default: null,
      },
      flat: {
        type: Boolean,
        default: false,
      },
      border: { type: String, default: null },
      xs: { type: Boolean, default: false },
    },

    computed: {
      styles() {
        return {
          background: this.background,
          color: this.color,
          'box-shadow': !this.flat && this.boxShadow ? '0px 5px 10px rgba(0, 0, 0, 0.08)' : null,
          border: this.border,
        };
      },

      classes() {
        return {
          'brand-tag-small': this.small,
          'brand-tag-flat': this.flat,
          'brand-tag-xs': this.xs,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-tag-el
    display: inline-flex
    align-items: center
    justify-content: center
    padding: 0.625rem 1rem
    font-weight: 500
    font-size: 0.875rem
    line-height: 0.875rem
    border-radius: 0.25rem
    flex-grow: 0

    &-small
      padding: 0.375rem 0.75rem
      cursor: pointer
    &-flat
      padding: 0
    &-xs
      font-size: .875rem
      padding: 0.25rem 0.625rem
</style>
