<template lang="pug">
.chips-holder(:class="classes")
  om-chip(
    v-for="item in items"
    :key="itemContent(contentKey ? item[contentKey] : item)"
    :small="small"
    :large="large"
    :removable="removable"
    :color="color"
    @remove="removeElement(item)"
  ) {{ itemContent(contentKey ? item[contentKey] : item) }}
</template>
<script>
  import OmChip from '@/components/Elements/Chips/Chip.vue';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmChips',
    components: {
      OmChip,
    },
    mixins: [designSystemMixin],
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      large: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'primary',
      },
      removable: {
        type: Boolean,
        default: false,
      },
      contentKey: {
        type: String,
      },
      wrap: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return {
          'chips-lg': this.large,
          'chips-sm': this.small,
          'chips-removable': this.removable,
          'design-system': this.designSystem,
          'flex-wrap': this.wrap,
        };
      },
    },
    methods: {
      removeElement(item) {
        const array = this.items;
        const index = array.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        }
        this.$emit('remove', item);
      },
      itemContent(item) {
        return item;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .chips-holder
    display: flex
    margin: 0 -6px
</style>
