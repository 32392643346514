<template lang="pug">
.vc-sketch-field.om-hex-alpha-row.mt-2.align-items-center.image-option-select
  .color-dark-grey--3.mr-2 {{ $t(label) }}
  select.font-weight-normal.form-control.form-control-select.image-option-select-item(
    v-model="value"
  )
    option(v-for="{ key, value } in options" :value="value" :key="key") {{ i18n ? $t(key) : key }}
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { imagePositionTracker } from '@/services/userInteractionTracker/tracker';

  export default {
    name: 'ImageOptionSelect',
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      options: { type: Array, required: true },
      i18n: { type: Boolean, default: true },
      defaultValue: { type: String, default: null },
      mobileDefaultValue: { type: [String, Number, Array, Object, null], default: null },
      colorInstance: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    computed: {
      ...mapState(['selectedElement']),
      value: {
        get() {
          if (this.colorInstance && this.label === 'position') {
            return this.colorInstance.getImagePosition();
          }
          if (this.colorInstance && this.label === 'repeat') {
            return this.colorInstance.getImageRepeat();
          }

          const _def = this.isMobileProperty ? this.mobileDefaultValue : this.defaultValue;
          return this.getValueOf(this.property, _def);
        },
        set(value) {
          if (value === 'crop' && !this.colorInstance.getImageCrop()) {
            this.showCropper();
            // In this case will be tracked at ImageCropper.vue > save
          } else if (this.colorInstance) {
            this.$emit('positionSelectChange', value);
            this.trackPositionChange(value);
          } else {
            this.setValueOf(this.property, value);
            window.om.bus.$emit('userInputChange', { property: this.property, value });
            this.trackPositionChange(value);
          }
        },
      },
      isMobileProperty() {
        return this.property.includes('mobile.');
      },
    },
    methods: {
      ...mapMutations(['hideColorPicker', 'showImageManagerV2']),
      ...mapActions(['showImageCropper']),
      showCropper() {
        this.hideColorPicker();
        this.showImageCropper({
          colorInstance: this.colorInstance,
          actionLocation: 'sidebar',
        });
      },
      trackPositionChange(value) {
        const component =
          this.property.indexOf('overlay') > -1 ? 'website-overlay' : this.selectedElement.type;
        imagePositionTracker.trackPositionTypeChange('fromSidebar', component, value);
      },
    },
  };
</script>

<style lang="sass">
  .image-option-select-item
    width: 116px !important
</style>
