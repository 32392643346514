<template lang="pug">
.sidebar-tab-item.d-flex-1.flex-grow.justify-content-center(@click="click")
  .sidebar-tab-name.align-items-center(:class="{ active }")
    slot(name="name")
      div {{ name }}
</template>

<script>
  import { nanoid } from 'nanoid';

  export default {
    props: {
      name: { type: String },
      id: { type: String, default: () => nanoid(9) },
    },
    data: () => ({
      active: false,
    }),
    methods: {
      click() {
        this.$parent.$emit('show-content', this.id);
        this.$emit('clicked', { name: this.name });
      },
      getId() {
        return this.id;
      },
      activate() {
        this.active = true;
      },
      deactivate() {
        this.active = false;
      },
    },
  };
</script>
