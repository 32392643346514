<template lang="pug">
.select-widget-card-box.col-12.col-sm-6.col-md-3
  .select-widget-wrapper
    .select-widget-card(
      :class="{ ...classes }"
      @click="onClick"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    )
      .select-widget-card-svg(v-if="icon || $slots.iconContent")
        #icon
          slot(name="iconContent")
            .select-widget-icon-svg
              inline-svg(:src="require(`./svgs/${icon}.svg`)")
      .d-flex.flex-column
        .select-widget-card-title(v-html="title")
</template>

<script>
  import { isDynamicContentEnabled } from '@/utils/features';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      icon: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 1,
      },
      title: {
        type: String,
      },
      checked: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        hovered: false,
      };
    },

    computed: {
      ...mapGetters(['accountFeatures']),
      classes() {
        return {
          'select-widget-checked': this.checked,
          inactive: this.dynamicContentNotSelectable,
        };
      },
      dynamicContentNotSelectable() {
        return this.icon === 'dynamicContent' && !isDynamicContentEnabled(this.accountFeatures);
      },
    },
    methods: {
      onClick($event) {
        if (this.dynamicContentNotSelectable) return;

        this.$emit('click', $event);
      },
    },
  };
</script>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'
  .select-widget
    &-wrapper
      border-radius: 4px
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
      text-align: center
      margin-bottom: 24px
      font-size: 0.875rem
      max-width: 196px
      margin: auto
    &-card
      padding: 8px
      font-weight: 500
      margin-bottom: 24px
      cursor: pointer
      &:hover
        box-shadow: 0 4px 10px rgb(0,0,0,0.15)
      &-box
        padding-left: 20px
        padding-right: 20px
      &-title
        margin-top: 6px
      &.inactive
        color: $om-gray-500
        cursor: default
        opacity: 0.8
        &:hover
          box-shadow: none
    &-icon-svg
      svg
        height: 90px
        width: 120px
</style>
