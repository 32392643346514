<template lang="pug">
.brand-color-box(:style="{ 'background-color': color }")
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'black',
      },
    },
  };
</script>

<style lang="sass">
  .brand-color-box
    width: 1.875rem
    height: 1.5rem
    border-radius: 0.25rem
</style>
