<template lang="pug">
.choose-segment-modal-summary
  om-body-text(bt700sm) {{ data.name }}
  TargetingSummary.mt-5(:frontendRules="filteredRules.applicable" :domain="domain")
  om-toast.mt-5(
    v-if="filteredRules.personalized.length"
    color="warning"
    type="side"
    :closable="false"
  )
    .choose-segment-modal-alert-title.mb-3 {{ $t('userSegment.modals.summary.personalizedTitle') }}
    TargetingSummary.mt-5(
      withoutStructure
      :frontendRules="filteredRules.personalized"
      :domain="domain"
    )
  om-toast.mt-5(
    v-if="filteredRules.notApplicable.length"
    color="danger"
    type="side"
    :closable="false"
  )
    .choose-segment-modal-alert-title.mb-3 {{ $t('userSegment.modals.summary.alertTitle') }}
    TargetingSummary.mt-5(
      withoutStructure
      :frontendRules="filteredRules.notApplicable"
      :domain="domain"
    )
</template>

<script>
  import TargetingSummary from '@/components/Segments/TargetingSummary.vue';
  import rulesFilterMixin from './rulesFilterMixin';

  export default {
    components: { TargetingSummary },
    mixins: [rulesFilterMixin],
    props: {
      data: {
        type: Object,
        required: true,
      },
      domain: {
        type: String,
        default: null,
      },
      campaignVersion: {
        type: Number,
        default: null,
      },
    },
    computed: {
      rules() {
        return this.data.rules;
      },
    },
  };
</script>
