<template lang="pug">
.accordion-v2-item(:class="{ done, active, fluid, disabled }")
  .accordion-v2-item-head.d-flex.align-items-center(
    @click="toggle"
    :class="{ 'cursor-pointer': openableDone }"
  )
    .accordion-v2-item-step-col.col-auto
      slot(name="icon")
        .accordion-v2-item-step.d-flex.justify-content-center.align-items-center {{ step }}
    .accordion-v2-item-name-col.flex-grow-1
      .accordion-v2-item-name
        slot(name="name")
    .accordion-v2-item-arrow-col.d-flex
      .accordion-v2-item-time.d-flex.align-items-center.mx-4(v-if="readingTime")
        om-body-text.gray-600.font-weight-400(bt400sm) {{ readingTime }}
      .accordion-v2-item-arrow.d-flex.justify-content-center.align-items-center
        UilCheck(v-if="done" size="1.5em" color="white")
        UilAngleUp(v-else-if="active" size="1.5em")
        UilAngleDown(v-else size="1.5em")
  .accordion-v2-item-body(v-if="!done || openableDone")
    slot
</template>

<script>
  import { UilCheck, UilAngleDown, UilAngleUp } from '@iconscout/vue-unicons';
  import { track } from '@/services/xray';

  export default {
    name: 'AccordionItem',
    components: { UilCheck, UilAngleDown, UilAngleUp },
    props: {
      step: { type: [String, Number], default: null },
      done: { type: Boolean, default: false },
      fluid: { type: Boolean, default: false },
      trackPrefix: { type: String, default: null },
      openDefault: { type: Boolean, default: false },
      readingTime: { type: [String, Number], default: null },
      disabled: { type: Boolean, default: false },
      openableDone: { type: Boolean, default: false },
    },
    data: () => ({
      active: false,
    }),
    watch: {
      active(status) {
        if (this.trackPrefix) {
          track(`${this.trackPrefix}`, { state: status ? 'open' : 'close' });
        }
      },
    },
    created() {
      if (this.openDefault) {
        this.toggle();
      }
    },
    methods: {
      toggle() {
        if (this.done && !this.openableDone) return;
        if (!this?.$parent?.$options?._componentTag?.includes?.('accordion')) {
          this.active = !this.active;
        } else {
          this?.$parent?.toggle?.(this);
        }
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .accordion-v2-item
    border: $om-gray-300 solid 1px
    border-radius: .25rem
    padding: .5rem 0
    padding-right: .5rem
    transition: box-shadow 150ms ease-out
    &.disabled
      pointer-events: none
      .accordion-v2-item-step-col
        img
          filter: grayscale(1)
          opacity: 0.75
      .accordion-v2-item-head
        color: $om-gray-600
      .accordion-v2-item-arrow
        svg
          fill: $om-gray-500
    &:not(.done)
      .accordion-v2-item-head
        cursor: pointer
      @media (hover: hover) and (pointer: fine)
        &:not(.active):hover
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.04)
    &-head
      color: $om-gray-700
      font-weight: 700
    &-step
      width: 2rem
      height: 2rem
      border-radius: 100%
      background-color: $om-gray-300
      transition: background-color .2s ease-in
      &-col
        padding-right: 1.25rem
        img
          max-width: 2.5rem
          height: auto
    &-arrow
      width: 1.5rem
      height: 1.5rem
    &-body
      display: none
      height: 0
      transform: scaleY(0)
      padding-left: 3.875rem
    &.fluid
      .accordion-v2-item-body
        padding: .5rem
        padding-left: 1rem
    &.active
      .accordion-v2-item
        &-step
          background-color: $om-orange-500
          color: white
        &-body
          display: block
          height: auto
          margin-top: 1rem
          transition: transform .1s ease-in-out
          transform-origin: top
          transform: scaleY(1)
    &.done
      background: $om-gray-300
      .accordion-v2-item
        &-step
          background-color: $om-alert-green-500
          color: white
        &-arrow
          width: 1.5rem
          height: 1.5rem
          border-radius: 100%
          background-color: $om-alert-green-500
</style>
