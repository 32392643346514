<template lang="pug">
.chip(:class="classes")
  slot(name="icon")
    .chip-before-icon(v-if="icon")
      component(v-if="icon" :is="iconComponent" :size="iconSize")
  .chip-content
    slot
  .chip-remove-icon(v-if="removable" @click="$emit('remove')")
    remove-svg
</template>
<script>
  import {
    UilHourglass,
    UilUsersAlt,
    UilEdit,
    UilStopwatch,
    UilCheckCircle,
  } from '@iconscout/vue-unicons';
  import RemoveSvg from './RemoveSvg.vue';
  import designSystemMixin from '../mixins/designSystem';
  import iconComponent from '../mixins/iconComponent';

  export default {
    name: 'OmChip',
    components: { RemoveSvg, UilHourglass, UilUsersAlt, UilEdit, UilStopwatch, UilCheckCircle },
    mixins: [designSystemMixin, iconComponent],
    props: {
      large: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      removable: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'primary',
        options: ['primary', 'secondary', 'gray', 'green', 'red', 'gradient', 'forest'],
        validator: (value) => {
          return ['primary', 'secondary', 'gray', 'green', 'red', 'gradient', 'forest'].includes(
            value.toLowerCase(),
          );
        },
      },
    },
    computed: {
      classes() {
        return {
          'chip-lg': this.large,
          'chip-sm': this.small,
          'chip-removable': this.removable,
          'design-system': this.designSystem,
          'chip-primary': this.color === 'primary',
          'chip-secondary': this.color === 'secondary',
          'chip-gray': this.color === 'gray',
          'chip-green': this.color === 'green',
          'chip-red': this.color === 'red',
          'chip-gradient': this.color === 'gradient',
          'chip-forest': this.color === 'forest',
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../../sass/variables/_colors.sass'
  .chips-holder
    display: flex
    margin: 0 -6px
  .chip
    font-size: .875rem
    line-height: 1.5rem
    padding: 0.25rem 0.285rem
    border-radius: 4px
    margin: 6px
    display: flex
    align-items: center
    justify-content: center

    &-before-icon
      display: flex

    &-primary
      background: $om-peach-200
      color: $om-orange-500

      .chip-remove-icon
        svg
          fill: $om-orange-300
        &:hover
          background: $om-orange-500-10
          svg
            fill: $om-orange-400

    &-secondary
      background: $om-gray-200
      color: $om-gray-700

      .chip-remove-icon
        svg
          fill: $om-gray-500
        &:hover
          background: $om-gray-500-20
          svg
            fill: $om-gray-600

    &-gray
      background: $om-gray-600
      color: $om-gray-50

      .chip-remove-icon
        svg
          fill: $om-gray-500
        &:hover
          background: $om-gray-500-20
          svg
            fill: $om-gray-400

    &-red
      background: $om-alert-red-200
      color: $om-alert-red-500

      .chip-remove-icon
        svg
          fill: $om-alert-red-500-40
        &:hover
          background: $om-alert-red-500-10
          svg
            fill: $om-alert-red-500-40
    &-gradient
      background: linear-gradient(to right, #8444E1 0%, #AE4D97 100%)
      color: $om-gray-50

      .chip-remove-icon
        svg
          fill: $om-gray-50
        &:hover
          background: #8444E1
          svg
            fill: $om-gray-50
    &-forest
      background: $om-alert-green-500
      color: $om-gray-50

      .chip-remove-icon
        svg
          fill: $om-gray-50
        &:hover
          background: $om-alert-green-600-10
          svg
            fill: $om-gray-50
    &-green
      background: $om-alert-green-200
      color: $om-alert-green-600

      .chip-remove-icon
        svg
          fill: $om-alert-green-600-40
        &:hover
          background: $om-alert-green-600-10
          svg
            fill: $om-alert-green-600-40

    &-content
      padding: 0 0.285rem
      font-weight: 400

    &-remove-icon
      border-radius: 4px
      width: 24px
      height:  24px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      $color: currentColor

    &-lg
      padding: 0.5rem

    &-sm
      padding: 0

    &-content
      cursor: default
</style>
