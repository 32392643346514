<template lang="pug">
.brand-tags(:class="classes")
  .brand-tags-backdrop(v-if="isEditMode" @click="leaveEditMode")
  .brand-tags-holder(@click="enterEditMode")
    tag(v-for="tag in selectedTags" :key="tag[trackBy]") {{ tag[label] }}
  transition(name="fade")
    .brand-tags-floating(v-if="isEditMode")
      .brand-tags-holder
        tag(
          v-for="tag in selectedTags"
          :key="tag[trackBy]"
          :removable="isEditMode"
          @remove="removeTagFromSelected(tag)"
        ) {{ tag[label] }}
        .brand-add-new-tag
          input(
            ref="input"
            type="text"
            v-model.trim="filterValue"
            @keydown.enter="handleInputEnter"
            @keydown.backspace="handleInputBackspace"
            @keydown.esc="leaveEditMode"
          )
      .brand-tags-floating-holder
        .brand-tags-floating-select-label {{ $t('selectOptionOrCreateNew') }}
        .brand-tags-floating-item-holder
          template(v-for="(item, itemIndex) in autocompleteItems")
            .brand-tags-floating-item(:key="item[trackBy]" @click.self="handleTagClick(item)")
              tag(@click.native="handleTagClick(item)") {{ item[label] }}
              .brand-tags-floating-item-options
                context-menu(
                  :tag="item"
                  :trackBy="trackBy"
                  :label="label"
                  :getRemoveWarningText="getRemoveWarningText"
                  @change="handleNameChange(item, $event)"
                  @remove="removeTag"
                )
                  template(slot="activator")
                    option-svg
        .brand-tags-floating-create(v-if="filterValue && !getMatchingExistingItemByFilterValue")
          .brand-tags-floating-create-label {{ $t('createNewTag') }}
          tag {{ filterValue }}
</template>

<script>
  import { generateObjectId } from '../../../util';
  import Tag from './Tag.vue';
  import OptionSvg from './OptionSvg.vue';
  import ContextMenu from './ContextMenu.vue';

  export default {
    components: { Tag, OptionSvg, ContextMenu },

    model: {
      prop: 'selectedTags',
      event: 'input',
    },

    props: {
      selectedTags: {
        type: Array,
        default: () => [],
      },
      items: {
        type: Array,
        default: () => [],
      },
      trackBy: {
        type: String,
        default: '_id',
      },
      label: {
        type: String,
        default: 'name',
      },
      getRemoveWarningText: {
        type: Function,
        default: () => this.$t('confirmationDialog'),
      },
    },

    data() {
      return {
        itemsCopy: [],
        filterValue: '',
        isEditMode: false,
      };
    },

    computed: {
      classes() {
        return {
          'brand-tags-edit-mode': this.isEditMode,
        };
      },
      autocompleteItems() {
        return this.itemsCopy.filter((i) => i[this.label].toLowerCase().includes(this.filterValue));
      },
      getMatchingExistingItemByFilterValue() {
        return this.itemsCopy.find((i) => i[this.label] === this.filterValue);
      },
    },

    watch: {
      isEditMode(v) {
        if (v) {
          this.$nextTick(() => {
            this.$refs.input.focus();
          });
        }
      },
      items() {
        this.setItemsCopy();
      },
    },

    created() {
      this.setItemsCopy();
    },

    methods: {
      handleInputEnter() {
        const existingItem = this.getMatchingExistingItemByFilterValue;
        const alreadyExists = !!existingItem;

        if (alreadyExists) {
          this.addExistingTag(existingItem);
        } else {
          const newTag = this.createNewTag(this.filterValue);
          this.addTag(newTag);
        }

        this.filterValue = '';
      },
      handleInputBackspace() {
        if (this.filterValue.length === 0) {
          // eslint-disable-next-line
          this.selectedTags.pop();
          this.$emit('input', this.selectedTags);
        }
      },
      handleTagClick(item) {
        this.addExistingTag(item);
        this.clearFilterValue();
      },
      handleNameChange(item, name) {
        this.changeTag(item, { name });
      },

      addExistingTag(tag) {
        const alreadyAdded = this.selectedTags.some((t) => t[this.trackBy] === tag[this.trackBy]);
        if (!alreadyAdded) {
          this.addTagToSelected(tag);
        }
      },
      createNewTag(label) {
        return { [this.trackBy]: generateObjectId(), [this.label]: label };
      },
      addTag(tag) {
        this.addTagToSelected(tag);
        this.addTagToItems(tag);
      },
      removeTag(tag) {
        this.removeTagFromSelected(tag);
        this.removeTagFromItems(tag);
      },
      changeTag(tag, overrides) {
        this.changeTagInSelected(tag, overrides);
        this.changeTagInItems(tag, overrides);
      },

      addTagToSelected(tag) {
        this.$emit('input', [...this.selectedTags, tag]);
      },
      removeTagFromSelected(tag) {
        this.$emit(
          'input',
          this.selectedTags.filter((v) => v[this.trackBy] !== tag[this.trackBy]),
        );
      },
      changeTagInSelected(tag, overrides) {
        const index = this.selectedTags.findIndex((t) => t[this.trackBy] === tag[this.trackBy]);
        if (index !== -1) {
          const selectedTagsCopy = this.selectedTags;
          selectedTagsCopy[index] = Object.assign(this.selectedTags[index], overrides);
          this.$emit('input', selectedTagsCopy);
        }
      },
      addTagToItems(tag) {
        this.itemsCopy.push(tag);
        this.$emit('addTag', tag);
      },
      removeTagFromItems(tag) {
        const index = this.itemsCopy.findIndex((i) => i[this.trackBy] === tag[this.trackBy]);
        if (index !== -1) {
          this.itemsCopy.splice(index, 1);
          this.$emit('removeTag', tag);
        }
      },
      changeTagInItems(tag, overrides) {
        const index = this.itemsCopy.findIndex((t) => t[this.trackBy] === tag[this.trackBy]);
        if (index !== -1) {
          this.itemsCopy[index] = Object.assign(this.itemsCopy[index], overrides);
          this.$emit('changeTag', { tag, overrides });
        }
      },

      enterEditMode() {
        this.isEditMode = true;
      },
      leaveEditMode() {
        this.clearFilterValue();
        this.$refs.input.blur();

        this.isEditMode = false;
      },

      setItemsCopy() {
        this.itemsCopy = JSON.parse(JSON.stringify(this.items));
      },
      clearFilterValue() {
        this.filterValue = '';
      },
    },
  };
</script>

<style lang="sass">
  .brand-tags
    border-radius: 4px
    border: 1px solid #D1D5DF
    max-width: 600px
    position: relative
    transition: .3s

    &-backdrop
      position: fixed
      left: 0
      top: 0
      height: 100%
      width: 100%
      z-index: 10

    &-holder
      display: flex
      flex-wrap: wrap
      align-items: flex-start
      width: 100%
      min-height: 34px
      border-top-left-radius: 3px
      border-top-right-radius: 3px
      overflow: hidden
      padding: 8px 9px 1px
      cursor: pointer
      background-color: white

      // &:hover
      //   background: rgba(55, 53, 47, 0.04)

      .brand-tag
        margin: 0px 6px 6px 0px

    &-holder,
    &-floating
      position: relative
      z-index: 11

    &-floating
      position: absolute
      width: 100%
      left: 0
      top: 0
      font-size: 14px
      background: white
      border-radius: 4px

      &-holder
        padding-top: 6px
        padding-bottom: 6px

      &-item
        display: flex
        align-items: center
        padding: 4px 14px
        line-height: 120%

        &-holder
          max-height: 25vh
          overflow-y: scroll
          scrollbar-width: none
          -ms-overflow-style: none
          &::-webkit-scrollbar
            width: 0 !important
            height: 0 !important

        &:hover
          background: rgba(55, 53, 47, 0.08)
          cursor: pointer

          // .brand-tags-floating-item-options
          //   opacity: 1

        &-options
          // opacity: 0
          position: relative
          margin-left: auto
          width: 18px
          height: 18px
          display: flex
          justify-content: center
          align-items: center

      &-select-label
        margin: 6px 0
        padding-left: 14px
        padding-right: 14px

      &-create
        display: flex
        align-items: center
        padding-left: 14px
        padding-right: 14px

        &-label
          margin-right: 6px

    .brand-add-new-tag
      display: flex
      align-items: center
      border: none
      padding: 0px
      width: auto
      background: transparent
      font-size: inherit
      line-height: 20px
      flex: 1 1 60px
      min-width: 60px
      margin: 0px 6px 6px 0px

      input
        font-size: inherit
        line-height: inherit
        border: none
        background: none
        width: 100%
        display: block
        resize: none
        padding: 0px
        height: 18px

        &:focus
          outline: 0

    &-edit-mode
      border-color: transparent

      .brand-tags-floating
        box-shadow: rgba(15,15,15,0.05) 0px 0px 0px 1px, rgba(15,15,15,0.1) 0px 3px 6px, rgba(15,15,15,0.2) 0px 9px 24px

      .brand-tags-holder
        background: rgba(242, 241, 238, 0.6)
        cursor: text
</style>
