<template lang="pug">
div(:class="{ row: sideLabel !== 'default' }")
  slot(name="label" v-if="label || $slots.label")
    label.form-label(
      v-if="label"
      :for="id"
      :class="{ 'col-form-label': sideLabel !== 'default', 'col-auto': sideLabel === 'auto', col: sideLabel === 'half', 'py-2': small && sideLabel !== 'default', 'py-3': large && sideLabel !== 'default' }"
    ) {{ label }}
  div(:class="{ col: sideLabel !== 'default' }")
    textarea.form-control(
      ref="input"
      :class="{ ...classes, 'is-invalid': !!$slots.error || $v.value.$error || error }"
      :value="value"
      :maxLength="maxLength"
      @change="$emit('input', $event.target.value)"
      v-bind="{ placeholder, id, disabled, rows }"
    )
    .form-text.text-danger(v-if="$slots.error || error")
      slot(name="error") {{ errorText }}
    .form-text(v-if="($slots.helperText && !$slots.error) || helperText")
      slot(name="helperText") {{ helperText }}
</template>

<script>
  import { UilExclamationCircle } from '@iconscout/vue-unicons';
  import { requiredIf } from 'vuelidate/lib/validators';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmTextarea',
    components: { UilExclamationCircle },
    mixins: [designSystemMixin],
    props: {
      id: {
        type: String,
        required: false,
      },
      value: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      sideLabel: {
        type: String,
        default: 'default',
        options: ['default', 'auto', 'half'],
        validator: (value) => {
          return ['default', 'auto', 'half'].includes(value.toLowerCase());
        },
      },
      placeholder: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      rows: {
        type: [Number, String],
        default: null,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorText: {
        type: String,
        default: null,
      },
      helperText: {
        type: String,
        default: null,
      },
      maxLength: {
        type: [Number, String],
        default: null,
      },
    },

    validations: {
      value: {
        required: requiredIf(function () {
          return this.required;
        }),
        isValidValue(value) {
          value = value || '';
          return this.required ? value.trim().length : true;
        },
      },
    },

    computed: {
      classes() {
        return {
          'form-control-sm': this.small,
          'form-control-lg': this.large,
          'design-system': this.designSystem,
        };
      },
    },
    methods: {
      validate() {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      focus() {
        this.$refs.input.focus();
      },
      getInputElement() {
        return this.$refs.input;
      },
    },
  };
</script>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'
  @import '../../../sass/components/_forms.sass'
</style>
