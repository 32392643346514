<template lang="pug">
.brand-notification-bar(:class="classes")
  slot
</template>

<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    mixins: [designSystemMixin],
    props: {
      width: {
        type: String,
        default: '100%',
      },
      top: {
        type: Boolean,
        default: true,
      },
      bottom: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classes() {
        return {
          'brand-notification-bar-top': this.top,
          'brand-notification-bar-bottom': this.bottom,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-notification-bar
    position: fixed
    right: 0
    min-height: 3.125rem
    width: calc(100% - 4rem)
    background-color: #538afe
    color: white
    padding: 0 2.1875rem
    display: flex
    align-items: center
    font-size: 0.875rem
    font-weight: 500
    z-index: 999

    &-top
      top: 0
    &-bottom
      bottom: 0
</style>
