<template lang="pug">
.om-pills.d-flex
  .om-pills-pill.cursor-pointer.pb-3(
    v-for="(pill, i) in pills"
    @click="$emit('select', pill.key)"
    :class="pillClasses(pill, i)"
  )
    om-body-text(bt700sm) {{ $te(pill.text) ? $t(pill.text) : pill.text }}
</template>

<script>
  import OmBodyText from '@/components/Elements/BodyText';

  export default {
    components: { OmBodyText },
    props: {
      pills: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
      },
    },
    methods: {
      pillClasses(pill, index) {
        const pillKeyedClass = `om-pills-${pill.key}`;
        return {
          active: pill.key === this.selected,
          'ml-4': !!index,
          [pillKeyedClass]: true,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors.sass'
  .om-pills
    border-bottom: 1px solid $om-gray-300
    &-pill
      border-radius: 0
      &.active
        border-bottom: 2px solid $om-orange-500
</style>
