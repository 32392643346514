<template lang="pug">
.pagination-wrapper
  ul.pagination(:class="classes")
    li.page-item.page-previous(:class="{ disabled: isFirst }")
      a.page-link(@click="onClick(actualPage - 1)")
        span.cursor-pointer
          uil-angle-left-b.page-link-icon
    li.page-item.page-item-first(:class="{ active: actualPage === 1 }")
      a.page-link.cursor-pointer(@click="onClick(1)") 1
    li.page-item.page-item-ellipsis.page-item-left-ellipsis(v-if="leftEllipsis")
      span.page-link &hellip;
    li.page-item(v-for="num in goToRange" :class="{ active: actualPage === num }")
      a.cursor-pointer.page-link(@click="onClick(num)") {{ num }}
    li.page-item.page-item-ellipsis.page-item-right-ellipsis(v-if="rightEllipsis")
      span.page-link &hellip;
    li.page-item.page-item-last(v-if="maxPage !== 1" :class="{ active: actualPage === maxPage }")
      a.cursor-pointer.page-link(@click="onClick(maxPage)") {{ maxPage }}
    li.page-item.page-next(:class="{ disabled: isLast }")
      a.page-link(@click="onClick(actualPage + 1)")
        span.cursor-pointer
          uil-angle-right-b.page-link-icon
    om-input#paginationInput.pagination-input.text-center(
      v-if="inputPlaceholder"
      :placeholder="inputPlaceholder"
      ref="paginationInput"
      type="number"
      v-model.number="jumpTo"
      small
      @change.native="goToPage()"
    )
</template>

<script>
  import OmInput from '@/components/Elements/Input';
  import { UilAngleRightB, UilAngleLeftB } from '@iconscout/vue-unicons';
  import * as _ from 'lodash';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmPagination',
    components: {
      UilAngleRightB,
      UilAngleLeftB,
      OmInput,
    },
    mixins: [designSystemMixin],
    model: {
      prop: 'value',
      event: 'paginateTo',
    },
    props: {
      value: {
        type: Number,
        default: 1,
      },
      maxPage: {
        type: Number,
        default: 5,
      },
      iconSize: {
        type: String,
        default: '1.25rem',
      },
      inputPlaceholder: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        jumpTo: null,
        pageNumWithoutEllipses: 7,
      };
    },
    computed: {
      actualPage: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('paginateTo', v);
        },
      },
      isFirst() {
        return this.actualPage === 1;
      },
      isLast() {
        return this.actualPage === this.maxPage;
      },
      leftEllipsis() {
        return this.actualPage > 4 && this.maxPage > this.pageNumWithoutEllipses;
      },
      rightEllipsis() {
        return this.maxPage > this.pageNumWithoutEllipses && this.actualPage + 4 <= this.maxPage;
      },
      goToRangeStart() {
        let start = 0;
        if (this.maxPage <= this.pageNumWithoutEllipses) {
          start = 2;
        } else {
          start = this.actualPage > 4 ? this.actualPage - 1 : 2;
        }
        return start;
      },
      goToRangeStop() {
        let end = 0;
        if (this.maxPage <= this.pageNumWithoutEllipses) {
          end = this.maxPage;
        } else if (this.actualPage + 3 >= this.maxPage) {
          end = this.maxPage;
        } else {
          end = this.actualPage + 2 >= this.maxPage - 1 ? this.maxPage - 1 : this.actualPage + 2;
        }
        return end;
      },
      goToRange() {
        return _.range(this.goToRangeStart, this.goToRangeStop);
      },
      classes() {
        return {
          'design-system': this.designSystem,
          'page-first': this.isFirst,
          'page-last': this.isLast,
          'page-left-ellipsis': this.leftEllipsis,
          'page-right-ellipsis': this.rightEllipsis,
          'pagination-with-input': this.inputPlaceholder !== null,
        };
      },
    },
    methods: {
      onClick(num) {
        if (this.actualPage === num || num < 1 || num > this.maxPage) return;
        this.actualPage = num;
        this.resetPageInput();
      },
      goToPage() {
        if (this.jumpTo < 1) {
          this.actualPage = 1;
        } else {
          this.actualPage = this.jumpTo > this.maxPage ? this.maxPage : this.jumpTo;
        }
        this.resetPageInput();
      },
      resetPageInput() {
        if (this.inputPlaceholder) {
          this.jumpTo = null;
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'pagination.sass'
</style>
