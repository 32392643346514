<template lang="pug">
.radio-select-cards(:class="classes")
  radio-select-card(
    v-for="(item, index) in items"
    :key="index"
    :index="index"
    :item="item"
    :icon="item.icon"
    :name="id"
    :value="value === index"
    @click="onClick(index)"
  )
</template>
<script>
  import designSystemMixin from '../mixins/designSystem';
  import RadioSelectCard from './RadioSelectCard.vue';

  export default {
    components: { RadioSelectCard },
    mixins: [designSystemMixin],
    props: {
      value: {
        type: [String, Array, Number],
        default: null,
      },
      id: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      classes() {
        return { 'design-system': this.designSystem };
      },
      state: {
        get() {
          return this.value;
        },
        set(v) {
          return v;
        },
      },
    },
    watch: {
      value(v) {
        this.state = v;
      },
    },
    methods: {
      onClick(index) {
        this.$emit('input', index);
      },
    },
  };
</script>
