<template lang="pug">
.colors
  .vc-sketch-field.om-hex-alpha-row.main-colors
    color-preset(
      :label="isUniversal ? 'brandColorsTitle' : 'mainColor'"
      :palette="mergedColors"
      :activeMainColorIndex="activeMainColorIndex"
      @change="handlePreset"
    )
  .vc-sketch-field.om-hex-alpha-row.recently-used-colors
    color-preset(
      label="recentlyUsedColors"
      type="recent"
      :palette="recentlyUsed"
      @change="handleRecent"
    )
</template>
<script>
  import { get as _get } from 'lodash-es';
  import tinycolor from 'tinycolor2';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import colorPreset from './ColorPreset.vue';

  export default {
    components: {
      colorPreset,
    },
    mixins: [themeColorsMixin],
    props: {
      disableAlpha: {
        type: Boolean,
        default: false,
      },
      activeMainColorIndex: {
        type: Number,
      },
    },
    computed: {
      getPaletteColors() {
        return _get(window, 'om.template.palette');
      },
      recentlyUsed() {
        const recentlyUsedColors = _get(this.$store.state, 'colorPicker.recentlyUsedColor');
        if (!recentlyUsedColors) return;

        if (this.disableAlpha) {
          const filteredColors = [];
          recentlyUsedColors.forEach((color) => {
            const colorObj = tinycolor(color);
            if (colorObj._a === 1) {
              filteredColors.push(color);
            }
          });
          return filteredColors;
        }

        return recentlyUsedColors;
      },
      mergedColors() {
        return this.getColors;
      },
    },
    methods: {
      handlePreset(data) {
        this.$emit('handlePreset', data);
      },
      handleRecent(data) {
        this.$emit('handleRecent', data);
      },
    },
  };
</script>
